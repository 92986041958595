<template>
  <div class="timeline-tip">
    <img class="timeline-tip__pointer" :src="pointer" />
    <div class="timeline-tip__popup">
      <div class="container">
        <div class="row timeline-tip__heading align-items-center">
          <div class="col">
            <div class="title">Важно!</div>
          </div>
          <div class="col-auto">
            <a class="history-back" @click="this.$emit('closeTip')"></a>
          </div>
        </div>
        <p>Чтобы выбрать временной период, нажмите на&nbsp;мигающий элемент в&nbsp;правом верхнем углу&nbsp;экрана.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TimelineTip",
  data(){
    return{
      pointer: require('@/static/img/arrow-timeline.svg')
    }
  }
}
</script>

<style scoped>
  .timeline-tip{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.5);
    z-index: 91;
  }
  .timeline-tip__pointer{
    width: 64px;
    height: 80px;
    position: absolute;
    top: calc(62px + 3rem);
    right: calc(60px + 1rem);
  }
  .timeline-tip__popup{
    background: #fff;
    padding: 2.4rem 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .timeline-tip__popup p{
    line-height: 1.42;
  }
  .timeline-tip__heading{
    margin-bottom: 2.4rem;
  }
  @media (min-width: 1280px) {
    .timeline-tip{
      display: none;
    }
  }
  @media(max-width: 575px){
    .timeline-tip__pointer{
      top: 110px;
    }
    .timeline-tip__popup p{
      font-size: 14px;
    }
  }
</style>
