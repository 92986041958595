import { createApp } from 'vue';
import './static/css/reset.css';
import './static/css/bootstrap-grid.css';
import './static/css/style.css';
import './static/css/adaptive.css';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import VueTheMask from "vue-the-mask";


let currentUrl = window.location.href;
let isTest = currentUrl.includes('we-demonstrate');

if (!isTest){
    axios.defaults.baseURL = 'https://museum.monp.ru/'; // боевой адрес
} else {
    axios.defaults.baseURL = 'https://mosnotmuz.we-demonstrate2.ru/'; // тестовый адрес
}

const vueApp = createApp(App);

vueApp.use(store).use(router).use(VueTheMask);
vueApp.config.globalProperties.$axios = axios;
vueApp.mount('#app');
