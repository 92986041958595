import { createStore } from 'vuex'

export default createStore({
  state: {
    currentChapter: 0,
    homeAnimationStatus: false,
    activeMedia: '', // photo, docs, video
    isFirstVisit: true,
    preloaded: true, // для внутреннего прелоадера
    timelineExpanded: false, // для мобильного таймлайна
    mobile: (window.innerWidth < 1280),
  },
  getters: {
    getCurrentChapter(state){
      return state.currentChapter;
    },
    isAnimationStarted(state){
      return state.homeAnimationStatus;
    },
    getActiveMedia(state){
      return state.activeMedia;
    },
    isFirstVisit(state){
      return state.isFirstVisit;
    },
    isPreloaded(state){
      return state.preloaded;
    },
    isTimelineExpanded(state){
      return state.timelineExpanded;
    },
    checkMobile(state){
      return state.mobile;
    }
  },
  mutations: {
    updateCurrentChapter(state, num){
      state.currentChapter = num;
    },
    updateAnimationStatus(state, status){
      state.homeAnimationStatus = status;
    },
    updateActiveMedia(state, type){
      state.activeMedia = type;
    },
    updateFirstVisit(state, status){
      state.isFirstVisit = status;
    },
    updatePreloaderStatus (state, status){
      state.preloaded = status;
    },
    updateTimeline(state, status){
      state.timelineExpanded = status;
    },
    updateMobileStatus(state, status){
      state.mobile = status;
    }
  },
  actions: {
    setCurrentChapter(ctx, num = 0){
      ctx.commit('updateCurrentChapter', num);
    },
    startHomeAnimation(ctx, status = true){
      ctx.commit('updateAnimationStatus', status);
    },
    setActiveMedia(ctx, type = ''){
      ctx.commit('updateActiveMedia', type);
    },
    setFirstVisit(ctx, status = false){
      ctx.commit('updateFirstVisit', status);
    },
    setPreloaderStatus(ctx, status = false){
      ctx.commit('updatePreloaderStatus', status)
    },
    expandTimeline(ctx, status = true){
      ctx.commit('updateTimeline', status);
    },
    setMobile(ctx, status = true){
      ctx.commit('updateMobileStatus', status);
    }
  },
  modules: {
  }
})
