<template>
  <div class="titlePage" :class="chapter">
    <div class="titlePage-chapter" v-html="heading"></div>
    <div class="titlePage-title row justify-content-center gx-0">
      <div class="col-8" :class="chapter === 'chapter_1' ? 'col-xl-7' : 'col-xxl-7'" v-html="subheading"></div>
    </div>
    <SharingArea :share="socials" :slide-id="item" />
  </div>
</template>

<script>
import SharingArea from "@/components/SharingArea";
export default {
  name: "TitlePage",
  components: {SharingArea},
  props: {
    item: String,
    chapter: String,
    socials: Object,
    heading: String,
    subheading: String
  }
}
</script>

<style scoped>
  .titlePage{
    padding-top: 24px;
  }
  .titlePage-chapter{
    font-size: 4.7rem;
    line-height: 1.2766;
    font-weight: bold;
    margin-bottom: 8px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }
  .titlePage-title{
    font-size: 1.9rem;
    line-height: 1.1579;
    letter-spacing: 0.05em;
  }

  .titlePage.chapter_1{
    font-family: 'Playfair Display', serif;
  }
  .titlePage.chapter_1 .titlePage-chapter{
    color: #B82F2F;
  }
  .titlePage.chapter_1 .titlePage-title{
    color: #2F4E3F;
  }

  .titlePage.chapter_2{
    font-family: 'Mulish', sans-serif;
  }
  .titlePage.chapter_2 .titlePage-chapter{
    color: #D76423;
    font-weight: 1000;
  }
  .titlePage.chapter_2 .titlePage-title{
    color: #2A0008;
  }

  .titlePage.chapter_3{
    font-family: 'Inter', sans-serif;
  }
  .titlePage.chapter_3 .titlePage-chapter{
    color: #8282C8;
    font-weight: 900;
  }
  .titlePage.chapter_3 .titlePage-title{
    color: #676767;
  }

  @media(min-width: 1280px){
    .titlePage{
      padding-top: 1.3rem;

      opacity: 0;
      transition: opacity 0.5s ease 2.2s;
    }
    .home.activated .titlePage{
      opacity: 1;
    }
  }
  @media(max-width: 575px){
    .titlePage-chapter{
      font-size: 50px;
      line-height: 1.2;
      margin-bottom: 16px;
    }
    .titlePage-title{
      font-size: 20px;
      line-height: 1.2;
    }
  }
</style>
