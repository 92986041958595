<template>
  <div class="innerPage" :class="chapter">
    <div class="row">
      <div class="col-md-10 col-xxl-9" :class="chapter === 'chapter_3' ? 'col-12' : 'col-10'">
        <div class="innerPage-title" v-html="heading"></div>
      </div>
    </div>
    <div class="innerPage-content">
      <swiper
          v-if="content.length"
          class="innerPage-slider"
          :class="{'disabled': !sliderEnabled}"
          :observer="true"
          :observe-parents="true"
          :observe-slide-children="true"
          :modules="modules"
          :speed = "500"
          effect="fade"
          :fade-effect="{crossFade: true}"
          :auto-height="true"
          :breakpoints="{576: {autoHeight: false}}"
          :pagination="{
              clickable: true,
              el: '.swiper-pagination',
              bulletClass: 'swiper-pagination-number',
              bulletActiveClass: 'swiper-pagination-number-active',
              dynamicBullets: true,
              dynamicMainBullets: 5,
              renderBullet: pagerCustom
            }"
          :navigation="{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }"
      >
        <swiper-slide
            v-for="item in content"
            class="item"
        >
          <div class="row">
            <div class="col-12" :class="{'col-sm-7 col-md-8': item.photo}">
              <div class="innerPage-slider__text" v-html="item.text"></div>
            </div>
            <div class="col-12 col-sm-5 col-md-4" v-if="item.photo">
              <div class="innerPage-slider__photo">
                <img :src="baseUrl + item.photo" />
              </div>
            </div>
          </div>
        </swiper-slide>
        <template v-slot:container-end>
          <div class="innerPage-slider__controls" v-show="content.length > 1">
            <div class="swiper-button-prev"></div>
            <div class="swiper-pagination"></div>
            <div class="swiper-button-next"></div>
          </div>
        </template>
      </swiper>
      <MediaPopup
          v-if="getActiveMedia && (activeMediaContent !== null)"
          :class="chapter"
          :content="activeMediaContent"
          :type="activeMediaContent.type"
          @closeMedia="closeMedia"
      />
    </div>

    <div class="innerPage-media">
      <template v-if="filteredMedia.length">
      <a
          class="button"
          v-for="btn in filteredMedia"
          @click="setActiveMedia(btn.type); activeMediaContent = btn; checkScroll()"
          :class="{'active': btn.type === getActiveMedia}"
      >{{getButtonText(btn.type)}}</a>
      </template>
      <div class="w-100 d-lg-none"></div>
      <template v-if="filteredButtons.length">
        <router-link
            v-for="button in filteredButtons[0].buttons"
            :to="`/patrons-${button.articleCode}`"
            class="button b-patrons"
        >
          <span>{{button.name}}</span>
        </router-link>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Pagination, Navigation, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

import 'swiper/css/bundle';
import MediaPopup from "@/components/MediaPopup";

export default {
  name: "InnerPage",
  components: {
    MediaPopup,
    Swiper,
    SwiperSlide
  },
  props: {
    slider: Boolean,
    refId: String,
    chapter: String,
    heading: String,
    content: Array,
    media: Array,
    patrons: Array,
  },
  computed:{
    ...mapGetters(['getActiveMedia', 'checkMobile']),
    sliderEnabled(){
      return this.slider;
    },
    filteredMedia(){
      return this.media.filter(function(item) {
        return item.content.length > 0;
      });
    },
    filteredButtons(){
      return this.patrons.filter((item) => item.chapterId === this.chapter)
    }
  },
  methods: {
    ...mapActions(['setActiveMedia']),
    pagerCustom: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + "</span>";
    },
    getButtonText(type){
      const buttonTexts = {
        'photo': 'Фото',
        'video': 'Видео',
        'docs': 'Документы'
      }
      return buttonTexts[type];
    },
    checkScroll(){
      this.$emit('checkScroll',{
        slideId: this.refId,
      });
    },
    closeMedia(){
      this.setActiveMedia();
      this.activeMediaContent = null;
    }
  },
  data(){
    return {
      baseUrl: this.$axios.defaults.baseURL,
      modules: [Pagination, Navigation, EffectFade],
      activeMediaContent: null,
      // buttons: []
    }
  },
  mounted(){
    // this.buttons = this.patrons.filter((item) => item.chapterId === this.chapter);
    // console.log(this.buttons);
  }
}
</script>

<style>
.innerPage-slider__text p{
  margin-top: 1.3em;
}
.innerPage-slider__text p:first-child{
  margin-top: 0;
}
.innerPage-slider__text h4,
.innerPage-slider__text h5{
  font-size: 1.3rem;
  line-height: 1.115;
  margin-bottom: 1.2rem;
}
.innerPage-slider__controls .swiper-button-next:after,
.innerPage-slider__controls .swiper-button-prev:after{
  display: none;
}
.innerPage-slider__controls .swiper-button-next,
.innerPage-slider__controls .swiper-button-prev{
  width: 12px;
  height: 14px;
  opacity: 0.5;
  background: transparent center center / contain no-repeat;
  display: inline-block;
}
.innerPage-slider__controls .swiper-button-next:hover,
.innerPage-slider__controls .swiper-button-prev:hover{
  opacity: 1;
}
.innerPage-slider__controls .swiper-button-next{
  background-image: url('img/arrow-right.svg');
  margin-left: 4px;
}
.innerPage-slider__controls .swiper-button-prev{
  background-image: url('img/arrow-left.svg');
  margin-right: 4px;
}
.innerPage-slider__controls .swiper-pagination-number{
  position: relative;
  cursor: pointer;
  font-size: 16px;
  line-height: 1.4;
  width: 1.4em;
  text-align: center;
  margin: 0 4px;
  color: #B4A990;
  display: inline-block;
}
.innerPage-slider__controls .swiper-pagination-number-active{
  color: #D9D9D9;
}
.innerPage-slider__controls .swiper-button-disabled{
  opacity: 0.2;
}
.innerPage-slider__controls .swiper-pagination-number-active-prev,
.innerPage-slider__controls .swiper-pagination-number-active-next{
  transform: scale(0.75);
}
.innerPage-slider__controls .swiper-pagination-number-active-prev-prev,
.innerPage-slider__controls .swiper-pagination-number-active-next-next{
  transform: scale(0.5);
}
</style>

<style scoped>
  .innerPage{
    padding-top: 5.555vh; /* 2.5rem */
  }
  @media(max-width: 1439px){
    .innerPage{
      padding-top: 3.25vh; /* 1.35rem */
    }
  }
  .innerPage-content{
    position: relative;
  }
  .innerPage-title{
    text-transform: uppercase;
    font-size: 2.1rem;
    line-height: 1.19;
    font-weight: bold;
    height: 2.38em;
    margin-bottom: 2.6rem;
    letter-spacing: 0.1em;
  }
  .innerPage.chapter_1 .innerPage-title{
    font-family: 'Playfair Display', serif;
    color: #B82F2F;
  }
  .innerPage.chapter_2 .innerPage-title{
    font-family: 'Mulish', sans-serif;
    color: #D76423;
  }
  .innerPage.chapter_3 .innerPage-title{
    font-family: 'Inter', sans-serif;
    color: #8282C8;
  }
  .innerPage-slider{
    padding-bottom: 22px;
    height: 52.222vh;
    /*min-height: 15rem;*/
    min-height: 21rem;
    margin-bottom: 6.666vh; /* 3rem */
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s ease 0s, visibility 0s ease 0s;
  }
  .innerPage-slider.disabled{
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: opacity 0.5s ease 0s, visibility 0s ease 0.5s;
  }
  .innerPage-slider .item{
    color: #D9D9D9;
    font-size: 1.1rem;
    line-height: 1.32;
  }

  .innerPage-slider__text{
    /*padding-bottom: 1.1rem;*/
  }
  .innerPage-slider__controls{
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
  }
  .innerPage-slider__controls > *{
    position: static;
    margin-top: 0;
  }
  .innerPage-slider__controls .swiper-pagination{
    position: relative;
    bottom: auto;
    top: auto;
    left: 43.5%;
  }
  .innerPage-slider__photo{
    background: transparent center center / 100% 100% no-repeat;
    display: inline-block;
  }
  .innerPage.chapter_1 .innerPage-slider__photo{
    padding: 0.3rem 1rem 0.5rem 0.6rem;
    background-image: url('img/papers-1.png');
  }
  .innerPage.chapter_2 .innerPage-slider__photo{
    padding: 1.2rem 1.7rem 0.8rem 0.9rem;
    background-image: url('img/papers-2.png');
  }
  .innerPage.chapter_3 .innerPage-slider__photo{
    padding: 0 0 0.2rem 2.1rem;
    background-image: url('img/papers-3.png');
  }
  .innerPage-slider__photo img{
    max-width: 100%;
    display: block;
    box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.35);
  }
  .innerPage-media{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .innerPage-media .button{
    /*margin-left: 2rem;*/
    margin-left: 1.5rem;
    min-width: 174px;
    width: auto;
  }
  .innerPage-media .button:first-child{
    margin-left: 0;
  }
  .innerPage-media .button.b-patrons{
    background: rgba(180, 169, 144, 0.15) !important;
  }
  @media(max-width: 1439px){
    .innerPage-media .button{
      margin-left: 1.2rem;
      min-width: 154px;
    }
  }
  @media (min-width: 1280px) and (max-height: 849px){
    .innerPage-slider{
      margin-bottom: 5vh;
    }
  }
  @media(min-width: 1280px) and (max-height: 799px){
    .innerPage-slider__photo img{
      max-height: 50vh;
    }
  }
  @media(max-width: 1279px){
    .innerPage{
      padding-top: 30px;
    }
    .innerPage-slider{
      padding-bottom: 50px;
      margin-bottom: 4.8vh;
      height: auto;
      /*min-height: 50vmin;*/
      min-height: 0;
    }
    .innerPage-slider.disabled{
      display: none;
    }
  }
  @media(max-width: 991px){
    .innerPage-title{
      font-size: 30px;
    }
    .innerPage-media .button{
      min-width: 0;
      width: calc(33.333% - 1.3rem);
      margin-left: 1.2rem;
    }
    .innerPage-media .b-patrons{
      margin-left: 0;
      margin-right: 1.2rem;
      margin-top: 1.3rem;
      width: calc(50% - 1.2rem);
    }
    .innerPage-media .b-patrons:last-child{
      margin-right: 0;
    }
  }
  @media(max-width: 767px){
    .innerPage-title{
      font-size: 2rem;
      height: 3.57em;
    }
    .innerPage.chapter_1 .innerPage-media .button{
      background: rgba(29, 57, 47, 0.80);
    }
    .innerPage.chapter_2 .innerPage-media .button{
      background: rgba(84, 39, 29, 0.80);
    }
    .innerPage.chapter_3 .innerPage-media .button{
      background: rgba(40, 40, 40, 0.80);
    }
    .innerPage-media .b-patrons{
      width: 100%;
      margin-right: 0;
    }
  }
  @media(max-width: 575px){
    .innerPage-slider__text{
      font-size: 16px;
      line-height: 1.5;
    }
    .innerPage-slider__photo{
      margin-top: 36px;
      width: 66.666%;
    }
  }
</style>
