<template>
  <div class="sharing">
    Поделиться
    <ul>
      <li v-for="social in socialLinks">
        <a :class="social.type" @click="shareLink(social.type)" :title="social.text"></a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SharingArea",
  props: {
    slideId: String,
    share: Object
  },
  methods:{
    shareVK(purl, ptitle, pimg) {
      let url  = 'https://vk.com/share.php?';
      url += 'url='          + encodeURIComponent(purl);
      url += '&title='       + encodeURIComponent(ptitle);
      url += '&image='       + encodeURIComponent(pimg);
      url += '&noparse=true';
      this.sharingPopup(url);
    },
    shareOK(purl, ptitle, pimg) {
      let url  = 'https://connect.ok.ru/offer?';
      url += 'url='          + encodeURIComponent(purl);
      url += '&title='       + encodeURIComponent(ptitle);
      url += '&imageUrl='    + encodeURIComponent(pimg);
      this.sharingPopup(url);
    },
    sharingPopup(url){
      window.open(url,'','toolbar=0,status=0'); // ,width=320,height=320
    },
    shareLink(type){
      switch (type){
        case 'copy':
          navigator.clipboard.writeText(this.baseUrl + '#' + this.slideId)
              .then(() => {
                // ссылка скопирована в буфер обмена
              })
              .catch(error => {
                console.log(error);
              });
          break;
        case 'vk':
          this.shareVK(this.baseUrl + '#' + this.slideId, this.shareTitle, this.shareImage);
          break;
        case 'ok':
          this.shareOK(this.baseUrl + '#' + this.slideId, this.shareTitle, this.shareImage);
          break;
        default:
          console.log(type);
      }
    }
  },
  data(){
    return{
      baseUrl: this.$axios.defaults.baseURL,
      socialLinks: [
          {type: 'vk', text: 'Поделиться ВКонтакте'},
          {type: 'ok', text: 'Поделиться в Одноклассниках'},
          {type: 'copy', text: 'Скопировать ссылку'}
      ],
      shareImage: this.$axios.defaults.baseURL + this.share.image,
      shareTitle: this.share.title,
    }
  }
}
</script>

<style scoped>
  .sharing{
    width: 386px;
    height: 130px;
    padding-left: 146px;
    padding-top: 42px;
    color: #5A5037;
    font-size: 18px;
    line-height: 1;
    letter-spacing: 0.05em;
    font-family: 'Jost', sans-serif;
    background: url('img/bg-footer.png') center bottom / contain no-repeat;
    position: absolute;
    bottom: 0;
    left: -146px;
  }
  .sharing ul{
    margin-top: 10px;
    font-size: 0;
  }
  .sharing li{
    margin-left: 16px;
    display: inline-block;
    vertical-align: top;
  }
  .sharing li:first-child{
    margin-left: 0;
  }
  .sharing a{
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: block;
    background: transparent center center / contain no-repeat;
    transition: opacity 0.3s ease;
  }
  .sharing a:hover{
    opacity: 0.8;
  }
  .sharing .vk{
    background-image: url('img/social-vk.svg');
  }
  .sharing .ok{
    background-image: url('img/social-ok.svg');
  }
  .sharing .tg{
    background-image: url('img/social-tg.svg');
  }
  .sharing .copy{
    background-image: url('img/social-copy.svg');
  }
  @media(min-width: 1280px) and (max-width: 1439px){
    .sharing{
      left: -126px;
    }
  }

  @media(max-width: 1279px){
    .sharing{
      width: 380px;
      font-size: 16px;
      height: 130px;
      padding-top: 30px;
      padding-left: 150px;
      left: -135px;
      background-size: 100% 100%;
    }
  }
</style>
