<template>
  <div class="timeline" :class="chapter" ref="timeline">
    <ul v-for="heading in sideTitles" :key="heading.chapterId" :class="{'active': heading.chapterId === chapterActive}">
      <li :key="heading.itemId">
        <a :class="{'active': heading.itemId === itemActive}"
           @click="chapter = heading.chapterId; item = heading.itemId; moveDecor()">
          {{heading.chapterNumber}}<br><span v-html="heading.chapterTitle"></span>
        </a>
      </li>
      <li v-for="title in heading.chapterPages" :key="title.itemId">
        <a :class="{'active': title.itemId === itemActive}"
           v-html="title.title"
           @click="chapter = heading.chapterId; item = title.itemId; moveDecor()">
        </a>
      </li>
    </ul>
    <div class="timeline-decor" :style="{ transform: 'translateY('+ decorTop +'px)'}"></div>
  </div>
</template>

<script>
export default {
  name: "TimeLine",
  props: ['chapter', 'item'],
  data(){
    return {
      decorTop: 0,
      sideTitles: null,
    }
  },
  computed: {
    chapterActive(){
      return this.chapter;
    },
    itemActive(){
      return this.item;
    }
  },
  methods: {
    async getTimeline(){
      this.$axios
          .get('api/sections/tree/')
          .then(response => {
            const finalRes = response.data.sideTitles;
            this.sideTitles = finalRes;
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => {
            this.updatePosition();
          });
    },
    updatePosition(){
      setTimeout(()=>{
        let el = this.$refs.timeline.querySelector('.timeline a.active');
        if (!el) {
          el = this.$refs.timeline.querySelector('.timeline ul:first-child li:first-child a');
        }
        if (el){
          this.decorTop = el.offsetTop + el.offsetHeight * 0.5;
        }
      }, 100);
    },
    moveDecor(){
      this.$emit('onActivation',{
        activeItemId: this.itemActive,
        activeChapterId: this.chapterActive
      });
      this.updatePosition();
    }
  },
  mounted(){
    this.getTimeline();
  }
}
</script>

<style scoped>
  .timeline{
    width: 286px;
    height: 100%;
    background: url('img/bg-rope.png') top right / 2px auto repeat-y;
    padding-top: 3rem;
    font-size: 16px;
    line-height: 1.12;
    color: rgba(180, 169, 144, 0.5);
    letter-spacing: 0.05em;
    position: relative;
  }
  .timeline.chapter_2{
    background: url('img/timeline-decor-radio.svg') top right / 24px auto repeat-y,
                linear-gradient(to left, rgba(255,255,255,0.13) 0%, rgba(255,255,255,0) 33px);
  }
  .timeline.chapter_3{
    background: none;
  }
  .timeline.chapter_3:before{
    content: '';
    width: 0.4rem;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background: #93939A;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  }
  .timeline.disabled{
    pointer-events: none;
    opacity: 0.3;
  }
  .timeline-decor{
    position: absolute;
    width: 75px;
    height: 65px;
    right: -49px;
    top: 0;
    background: url('img/timeline-decor.png') left top / contain no-repeat;
    margin-top: -30px;
    /*transform: translateY(27px);*/
    transition: transform 0.5s ease;
    -webkit-filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
    filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
  }
  .timeline.chapter_2 .timeline-decor{
    z-index: -1;
    width: 48px;
    height: 7px;
    margin-top: -3px;
    right: 4px;
    background: linear-gradient(to bottom, #FE564E 0%, #FE564E 42.857%, #CA453F 42.857%, #CA453F 100%);
  }
  .timeline.chapter_3 .timeline-decor{
    width: 34px;
    height: 20px;
    margin-top: -10px;
    right: -3px;
    background: none;
  }
  .timeline.chapter_3 .timeline-decor:before{
    content: '';
    background: #DD137B;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    margin-top: -10px;
    top: 50%;
    left: 0;
  }
  .timeline.chapter_3 .timeline-decor:after{
    content: '';
    width: 14px;
    height: 14px;
    border: 1px solid #000;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -7px;
  }
  .timeline ul{
    margin-top: 20px;
  }
  .timeline ul.active{
    color: #B4A990;
  }
  .timeline li{
    margin-top: 20px;
    display: none;
  }
  .timeline ul:first-child,
  .timeline li:first-child{
    margin-top: 0;
  }
  .timeline ul.active li,
  .timeline li:first-child{
    display: list-item;
  }
  .timeline a{
    cursor: pointer;
    position: relative;
    display: block;
    padding-right: 40px;
  }
  .timeline ul.active a:hover{
    color: #968B72;
  }
  .timeline.chapter_3 a:before{
    content: '';
    width: 0.8rem;
    height: 0.4rem;
    margin-top: -0.2rem;
    position: absolute;
    top: 50%;
    right: 0;
    background: #93939A;
  }
  .timeline a.active{
    cursor: default;
    color: #B82F2F !important;
  }
  @media(max-width: 1279px){
    .timeline{
      width: auto;
      height: auto;
      min-height: 100%;
      padding: 2.4rem 0 24px;
      max-width: 930px;
      margin: 0 auto;
      font-size: 19px;
      line-height: 1.15;
    }
    .timeline a{
      padding-right: 50px;
    }
  }
  @media(max-width: 991px){
    .timeline{
      max-width: 690px;
    }
  }
  @media(max-width: 767px){
    .timeline{
      max-width: 510px;
    }
  }
</style>
