<template>
  <div class="home" :class="{'activated': isAnimationStarted}">
    <swiper
        class="sliderBacks"
        :resize-obsever="false"
        :modules="modules"
        :direction = "'vertical'"
        :speed = "500"
        :slides-per-view="checkMobile ? 'auto' : 1"
        :free-mode="{enabled: checkMobile, sticky: false}"
        :auto-height="checkMobile"
        mousewheel
        @swiper="setFirstSwiper"
        :hashNavigation="{watchState: true}"
        @slideChange="onSlideChange"
        :effect="checkMobile ? 'slide' : 'fade'"
        :fade-effect="{crossFade: true}"
        :observer="true"
        :observe-parents="true"
        :observe-slide-children="true"
    >
      <swiper-slide
          v-for="item in contentSlides"
          class="item"
          :data-hash="item.itemId"
          :ref="item.itemId"
          :class="`${item.bgType} ${item.chapterId}`"
      >
        <TitlePageDecor v-if="item.itemId.includes('chapter')" :chapter="item.chapterId" />
        <InnerPageDecor v-else :chapter="item.chapterId" />
        <div class="container h-100 home-content">
          <div class="row h-100">
            <div class="col-12 col-xl-9 h-100">
              <TitlePage
                  v-if="item.itemId.includes('chapter')"
                  :item="item.itemId"
                  :chapter="item.chapterId"
                  :socials="item.socialsData"
                  :heading="item.chapterNumber"
                  :subheading="item.pageTitle"
              />
              <InnerPage
                  v-else
                  :ref-id="item.itemId"
                  :chapter="item.chapterId"
                  :heading="item.heading"
                  :content="item.content"
                  :media="item.media"
                  :slider="sliderEnabled"
                  :patrons="this.patronsButtons"
                  @checkScroll="checkScroll"
              />
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <div class="timeline-wrapper" :class="{'expanded': isTimelineExpanded}">
      <TimeLine
          ref="timeline"
          :chapter="currentChapter"
          :item="currentItem"
          :class="{'disabled': !sliderEnabled}"
          @onActivation="onActivation"
      />
    </div>

    <Transition name="modal">
      <TimelineTip v-if="showTip" @closeTip="closeTip" />
    </Transition>

    <a
        class="timeline-mobile d-xl-none"
        :class="{'disabled': !sliderEnabled}"
        @click="expandTimeline(!showTip)"
    ></a>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Mousewheel, EffectFade, HashNavigation, FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

import 'swiper/css/bundle';

import TimeLine from "@/components/TimeLine";
import TitlePage from "@/components/TitlePage";
import InnerPage from "@/components/InnerPage";
import TitlePageDecor from "@/components/TitlePageDecor";
import InnerPageDecor from "@/components/InnerPageDecor";
import TimelineTip from "@/components/TimelineTip";

export default {
  name: 'HomeView',
  components: {
    TimelineTip,
    InnerPageDecor,
    TitlePageDecor,
    TimeLine,
    Swiper,
    SwiperSlide,
    TitlePage,
    InnerPage
  },
  computed: {
    ...mapGetters(['getCurrentChapter', 'getActiveMedia', 'isAnimationStarted', 'isTimelineExpanded', 'checkMobile']),
  },
  methods: {
    ...mapActions(['setCurrentChapter', 'setPreloaderStatus', 'expandTimeline']),
    setFirstSwiper(swiper) {
      this.firstSwiper = swiper;
    },
    onSlideChange(swiper) {
      this.currentChapter = this.contentSlides[swiper.activeIndex].chapterId;
      this.currentItem = this.contentSlides[swiper.activeIndex].itemId;
      this.$refs.timeline.updatePosition();

      let num = parseInt(this.currentChapter.replace('chapter_',''));
      this.setCurrentChapter(num);

      if (this.isAnimationStarted && this.checkActiveSlide()){
        this.firstSwiper.slides[swiper.activeIndex].querySelector('.stage').classList.add('passed');
        // console.log('Slide changed: ' + swiper.activeIndex);

        this.checkActiveChapter(swiper.activeIndex);
      }
    },
    onActivation(data){
      let newIndex = this.$refs[data.activeItemId][0].swiperSlideIndex;
      this.firstSwiper.slideTo(newIndex);
      this.expandTimeline(false);
    },
    checkScroll(data){
      if (this.checkMobile) {
        let currentIndex = this.$refs[data.slideId][0].swiperSlideIndex;
        this.firstSwiper.slideTo(currentIndex);
      }
    },
    checkActiveSlide(){
      return (this.getCurrentChapter > 1 && this.firstSwiper.activeIndex > 0)
          || (this.getCurrentChapter === 1 && this.firstSwiper.activeIndex === 0);
    },
    checkActiveChapter(active){
      if (this.firstSwiper.slides[active].classList.contains('pattern')){
        let el = document.querySelectorAll('.pattern.chapter_'+this.getCurrentChapter);
        for (let i = 0; i < el.length; i++) {
          el[i].querySelector('.stage').classList.add('passed');
        }
      }
    },
    mountSliders(){
      let currentHash = this.$route.hash.replace('#','');
      if (currentHash){
        let correctIndex = this.$refs[currentHash][0].swiperSlideIndex;
        if (correctIndex > 0){
          this.firstSwiper.slideTo(correctIndex);
        }
      }

      if (this.getCurrentChapter === 0) this.setCurrentChapter(1);

      if (this.isAnimationStarted && this.checkActiveSlide()){
        let active = this.firstSwiper.activeIndex;
        this.firstSwiper.slides[active].querySelector('.stage').classList.add('passed');
        // console.log('Mounted: ' + active);
      }
    },
    async getPageContent(){
      this.$axios
          .get('api/main-str/list-items/')
          .then(response => {
            const finalRes = response.data.contentSlides;
            this.contentSlides = finalRes.flat();
            this.currentItem = this.contentSlides[0].itemId;
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => {
            this.mountSliders();
            this.setPreloaderStatus(true);
          });
    },
    async getButtons(){
      this.$axios
          .get('api/chapter-buttons/')
          .then(response => {
            const finalRes = response.data;
            this.patronsButtons = finalRes;
          })
          .catch(error => {
            console.log(error);
          });
    },
    closeTip(){
      this.showTip = false;
    }
  },
  watch: {
    sliderEnabled: function(){
      console.log(this.sliderEnabled);
      if (!this.sliderEnabled) {
        this.firstSwiper.disable();
      } else {
        this.firstSwiper.enable();
      }
    },
    getActiveMedia: function(){
      console.log(this.getActiveMedia);
      this.sliderEnabled = !this.getActiveMedia;
    },
    isAnimationStarted: function(){
      if (this.isAnimationStarted){
        let active = this.firstSwiper.activeIndex;
        this.firstSwiper.slides[active].querySelector('.stage').classList.add('passed');
        // console.log('First init: ' + active);

        this.checkActiveChapter(active);

        setTimeout(() => {this.showTip = true}, 800);
      }
    },
    '$route.hash' (to, from){
      if (to.includes('chapter')){
        this.hashChapter = true;
        let newIndex = this.$refs[to.replace('#','')][0].swiperSlideIndex;
        this.firstSwiper.slideTo(newIndex);
      } else {
        this.hashChapter = false;
      }

      if (this.isAnimationStarted
          && this.getCurrentChapter === 1
          && !this.hashChapter
          && this.hashChapter !== null) {
        let active = this.firstSwiper.activeIndex;
        this.firstSwiper.slides[active].querySelector('.stage').classList.add('passed');
        // console.log('hash changed: ' + active);

        this.checkActiveChapter(active);
      }
    }
  },
  data(){
    return {
      showTip: false,
      sliderEnabled: true,
      hashChapter: null,

      firstSwiper: null,
      modules: [Mousewheel, EffectFade, HashNavigation, FreeMode],

      currentChapter: 'chapter_1',
      currentItem: '',
      contentSlides: null,
      patronsButtons: [],
    }
  },
  mounted(){
    this.getPageContent();
    this.getButtons();
  }
}
</script>

<style>
.sliderBacks > .swiper-wrapper{
  height: 100% !important;
}
</style>
<style scoped>
  .home{
    height: 100%;
    position: relative;
    overflow: hidden;
  }
  .home-content{
    position: relative;
    z-index: 1;
  }
  .timeline-mobile{
    display: block;
    position: absolute;
    top: 30px;
    right: 1rem;
    width: 55px;
    height: 55px;
    cursor: pointer;
    border-radius: 50%;
    background: rgba(255,255,255,0.8) url('../static/img/icon-timeline.svg') center center / 45px 45px no-repeat;
    z-index: 1;
  }
  .timeline-mobile.disabled{
    pointer-events: none;
    opacity: 0.3;
  }
  .timeline-tip + .timeline-mobile{
    z-index: 91;
  }
  .timeline-mobile:before,
  .timeline-mobile:after{
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid #fff;
    opacity: 0.5;
    border-radius: 50%;
    animation: puls 8s infinite ease-in-out;
  }
  .timeline-mobile:before{
    width: 61px;
    height: 61px;
  }
  .timeline-mobile:after{
    width: 67px;
    height: 67px;
    animation-delay: 0.4s;
    border-color: rgba(255,255,255,0.6);
  }
  .timeline-wrapper{
    position: absolute;
    height: 100%;
    top: 0;
    left: calc(50% + 408px);
    z-index: 1;
  }
  @keyframes puls {
    0% {
      opacity: 0.5;
    }
    10% {
      opacity: 1;
    }
    20%, 100% {
      opacity: 0.5;
    }
  }
  @media(min-width: 1280px){
    .timeline-wrapper{
      opacity: 0;
      transition: opacity 0.5s ease 2.5s;
    }
    .home.activated .timeline-wrapper{
      opacity: 1;
    }
  }
  .sliderBacks{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .sliderBacks .item{
    position: relative;
  }
  .sliderBacks .item.fullscreen{
    height: 100%;
    background: url("../static/img/bg-light.jpg") center center / cover no-repeat;
    z-index: 1;
  }
  .sliderBacks .item.pattern{
    min-height: 100%;
    background: transparent 0 0 / 94px 94px repeat;
  }
  .sliderBacks .item.pattern.chapter_1{
    background-image: url("../static/img/bg-pattern.jpg");
  }
  .sliderBacks .item.pattern.chapter_2{
    background-image: url("../static/img/bg-pattern-2.jpg");
  }
  .sliderBacks .item.pattern.chapter_3{
    background-image: url("../static/img/bg-pattern-3.jpg");
  }
  .sliderBacks .item.pattern:after{
    content: '';
    width: 100%;
    height: 21.333vh;
    max-height: 9.6rem;
    background: url('../static/img/bg-header.png') center top / auto 100% no-repeat;
    position: absolute;
    top: 0;
    left: 0;
  }
  .sliderBacks .item.pattern:before{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .sliderBacks .item.pattern.chapter_1:before{
    background: linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,0.29) 100%);
  }
  .sliderBacks .item.pattern.chapter_2:before{
    background: linear-gradient(to left, rgba(0,0,0,0) 0%, rgba(0,0,0,0.29) 100%);
  }
  @media(min-aspect-ratio: 1920/900){
    .sliderBacks .item.pattern:after{
      background-size: 100% 100%;
    }
  }

  @media (max-width: 1439px) and (min-width: 1280px) and (max-height: 799px){
    .sliderBacks .item.pattern:after{
    max-height: 150px;
    }
  }
  @media (max-width: 1439px) and (min-width: 1280px) and (max-height: 759px){
    .sliderBacks .item.pattern:after{
      max-height: 140px;
      background-size: 100% 100%;
    }
  }
  @media (max-width: 1439px) and (min-width: 1280px) and (max-height: 699px){
    .sliderBacks .item.pattern:after{
      max-height: 130px;
    }
  }

  @media(max-width: 1439px){
    .timeline-wrapper{
      left: calc(50% + 335px);
    }
  }
  @media(max-width: 1279px){
    .timeline-wrapper{
      width: 100%;
      left: 0;
      z-index: 85;
      background: #fff url('../static/img/bg-light.jpg') center top / cover no-repeat;
      padding: 0 35px 0 30px;
      overflow-x: hidden;
      overflow-y: auto;
      transform: translate(100%, 0);
      transition: transform 0.5s ease;
    }
    .timeline-wrapper.expanded{
      transform: translate(0, 0);
    }
    .sliderBacks .item.pattern:after{
      max-height: none;
      height: 210px;
      margin-top: -48px;
      background: url('../components/img/paper-heading-mobile.png') center center / 100% 100% no-repeat;
    }
    .home-content{
      padding-bottom: 60px;
    }
  }
  @media(max-width: 991px){
    .sliderBacks .item.pattern:after{
      height: 173px;
    }
  }
  @media(max-width: 767px){
    .sliderBacks .item.pattern:after{
      height: 228px;
    }
  }
  @media(max-width: 575px){
    .sliderBacks .item.pattern:after{
      height: 173px;
    }
  }
</style>
