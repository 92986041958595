<template>
  <div class="modal">
    <div class="container h-100">
      <div class="row h-100 align-items-center">
        <div class="col-12">
          <div class="modal-window">
            <div class="modal-content">
              <a class="modal-close" @click="this.$emit('modalClose')"></a>
              <div class="modal-title">
                <slot name="title"></slot>
              </div>
              <div class="modal-content__wrapper">
                <div class="modal-text">
                  <slot></slot>
                </div>
                <div class="modal-image">
                  <slot name="image"></slot>
                </div>
              </div>
              <div class="modal-video">
                <slot name="video"></slot>
              </div>
              <div class="modal-gallery">
                <slot name="gallery"></slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalWindow"
}
</script>

<style>
  .modal-image img{
    display: block;
    margin-left: auto;
    margin-right: 0;
    max-width: calc(100% - 1.5rem);
    max-height: 558px;
  }
  .modal-text p{
    margin-bottom: 1.2rem;
  }
  .modal-text p:last-child{
    margin-bottom: 0;
  }
  .modal-video iframe{
    display: block;
    margin: 1.5rem auto 0;
    width: 100%;
    max-width: 872px;
    height: 490px;
  }
  .modal-gallery .swiper{
    padding: 2rem 1.5rem 0;
  }
  .modal-gallery .swiper-wrapper{
    align-items: center;
  }
  .modal-gallery .swiper-button-prev:after,
  .modal-gallery .swiper-button-next:after{
    display: none;
  }
  .modal-gallery .swiper-button-prev,
  .modal-gallery .swiper-button-next{
    margin-top: 1rem;
    width: 1.1rem;
    height: 2.1rem;
    background: transparent center center / contain no-repeat;
    transform: translateY(-50%);
    position: absolute;
    top: 50%;
    cursor: pointer;
    transition: opacity 0.4s ease;
  }
  .modal-gallery .swiper-button-disabled{
    opacity: 0;
    cursor: default;
    pointer-events: none;
  }
  .modal-gallery .swiper-button-next{
    right: 0;
    background-image: url('../components/img/arrow-right-big.svg');
  }
  .modal-gallery .swiper-button-prev{
    left: 0;
    background-image: url('../components/img/arrow-left-big.svg');
  }
  .modal-gallery img{
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }
  .modal-gallery span{
    display: block;
    text-align: center;
    padding-top: 1.5rem;
    /*grid-area: gDesc;*/
  }
  @media(min-height: 700px){
    .modal-gallery img{
      max-height: 700px;
    }
  }
  @media (max-width: 767px) {
    .modal-image img{
      max-width: 100%;
      max-height: none;
      width: 100%;
      margin-left: 0;
      margin-top: 16px;
    }
    .modal-video iframe{
      height: 66vw;
      max-height: 90vh;
    }
  }
</style>
<style scoped>
  .modal{
    z-index: 100;
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    top: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .modal-window{
    background: #fff;
    padding: 2.1rem;
    position: relative;
  }
  .modal.gallery .modal-window{
    /*height: calc(100vh - 5rem);*/
  }
  .modal-content{
    position: relative;
    max-width: 1210px;
    margin: 0 auto;
  }
  .modal.gallery .modal-content{
    height: 100%;
  }
  .modal-close{
    width: 28px;
    height: 28px;
    background: url('img/icon-close.svg') center center / contain no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    transition: all 0.4s ease;
    z-index: 10;
  }
  .modal-close:hover{
    transform: rotate(180deg);
  }
  .modal-title{
    color: #5A5037;
    text-transform: uppercase;
    font-size: 1.5rem;
    line-height: 1.2;
    font-family: 'Cormorant Garamond', serif;
    font-weight: 500;
    max-height: 2.4em;
    padding-right: 3.5rem;
    margin-bottom: 2.1rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .modal.gallery .modal-content__wrapper,
  .modal.gallery .modal-video,
  .modal.gallery .modal-title{
    display: none;
  }
  .modal-content__wrapper{
    display: flex;
    flex-wrap: wrap;
  }
  .modal-text{
    flex: 1 0 0;
    font-size: 1.1rem;
    line-height: 1.32;
  }
  .modal-image{
    max-width: 42%;
  }
  @media(min-height: 700px){
    /*.modal-gallery{*/
    /*  height: 100%;*/
    /*  display: grid;*/
    /*  grid-template:*/
    /*    "gImg" 1fr*/
    /*    "gDesc" auto / 100%;*/
    /*}*/
  }
  @media(max-width: 767px){
    .modal > .container{
      max-width: none;
      padding-left: 0;
      padding-right: 0;
    }
    .modal-window{
      padding: 24px;
    }
    .modal-text{
      flex: 0 0 100%;
      width: 100%;
    }
    .modal-image{
      max-width: 100%;
      width: 100%;
    }
    .modal-title{
      margin-bottom: 24px;
      padding-right: 56px;
      max-height: 4.8em;
      -webkit-line-clamp: 4;
    }
  }
  @media(max-width: 575px){
    .modal-window{
      padding: 24px 15px;
    }
    .modal-close {
      width: 18px;
      height: 18px;
    }
    .modal-text{
      font-size: 14px;
      line-height: 20px;
    }
    .modal-title{
      font-size: 20px;
      padding-right: 33px;
    }
  }
</style>
