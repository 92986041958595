<template>
  <div class="start-screen" :class="{'loaded': isLoaded, 'ready': isReady, 'started': isStarted}" @wheel.prevent="startView">
    <div class="start-screen__wrapper">
      <div class="start-screen__cloud top-right before"></div>
      <div class="start-screen__cloud left-bottom before"></div>
      <div class="container h-100 position-relative">
        <div class="row h-100 align-items-center">
          <div class="col-12">
            <div class="start-screen__subheading first">Добро пожаловать</div>
            <img class="start-screen__heading" :src="decorHeading" />
            <div class="start-screen__subheading last">Московской области</div>
            <div class="start-screen__intro">
              <p>Перед нами история сотен лет служения закону и тысяч людей, исполнявших его.</p>
              <p>И она начинается здесь…</p>
            </div>
          </div>
        </div>
      </div>
      <div class="start-screen__cloud top-right after"></div>
      <div class="start-screen__cloud left-bottom after"></div>

      <a class="start-screen__start" @click="startView"></a>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: "StartScreen",
  props: ['loaded'],
  computed: {
    isLoaded(){
      return this.loaded;
    }
  },
  methods: {
    ...mapActions(['startHomeAnimation', 'setFirstVisit']),
    startView(){
      if (this.isReady) {
        this.isStarted = true;
        this.startHomeAnimation();
        setTimeout(()=>{
          this.setFirstVisit();
        },510)
      }
    }
  },
  data(){
    return{
      isReady: false,
      isStarted: false,
      decorHeading: require('@/static/img/intro-text.svg')
    }
  },
  watch: {
    isLoaded: function(){
      if (this.isLoaded){
        setTimeout(()=>{
          this.isReady = true;
        },3500)
      }
    }
  },
  mounted(){

  }
}
</script>

<style scoped>
  .start-screen{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    z-index: 900000;

    overflow: hidden;
    transition: visibility 0s linear 500ms;
  }
  .start-screen__wrapper{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #B4A990;
    background: #fff;
    font-family: 'Cormorant Garamond', serif;
    font-size: 1.8rem;
    line-height: 1.16;
    /*padding-top: 9.17vh;*/
    padding: 11.2vh 0;

    overflow: hidden;
    transition: transform 500ms ease-in-out;
  }
  .start-screen__cloud{
    position: absolute;
  }
  .start-screen__cloud.top-right{
    top: 0;
    right: 0;
    height: 63.7vh;
    width: 53.02vw;
    background: url('img/cloud-top-right.png') right top / contain no-repeat;
    transform-origin: top right;
  }
  .start-screen__cloud.left-bottom{
    left: 0;
    bottom: 0;
    height: 61.39vh;
    width: 62.03vw;
    background: url('img/cloud-left-bottom.png') left bottom / contain no-repeat;
    transform-origin: left bottom;
  }
  .start-screen__heading{
    max-width: 100%;
    /*margin: 2.2rem 0;*/
    margin: 4.07vh auto;
    display: block;

    opacity: 0.1;
    transform: scale(0.25);
  }
  .start-screen__subheading{
    font-family: 'Jost', sans-serif;
    font-weight: 200;
    font-size: 3rem;
    line-height: 1.16;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    padding: 0 1rem;
    /*opacity: 0.3;*/
    opacity: 0;
  }
  .start-screen__subheading.first{
    transform: translateX(-50%);
  }
  .start-screen__subheading.last{
    text-align: right;
    transform: translateX(50%);
  }
  .start-screen__intro{
    text-align: center;
    margin-top: 6.3vh;
    /*opacity: 0.3;*/
    opacity: 0;
  }
  .start-screen__intro p{
    margin-top: 0.4rem;
  }

  .start-screen__start{
    width: 40px;
    height: 62px;
    background: url('img/icon-scroll-down.svg') center center / contain no-repeat;
    position: absolute;
    left: 50%;
    bottom: 11.2vh;
    margin: 0 0 -62px -20px;

    opacity: 0;
    visibility: hidden;
    cursor: pointer;
  }
  .start-screen__start:hover{
    background-image: url('img/icon-scroll-down_hover.svg');
  }

  .start-screen.started{
    visibility: hidden;
  }
  .start-screen.started .start-screen__wrapper{
    transform: translateY(-100%);
  }

  /* animation */
  .start-screen__heading{
    transition: transform 2s ease-in-out 0.7s, opacity 2s ease-in-out 0.7s;
  }
  .start-screen.loaded .start-screen__heading{
    opacity: 1;
    transform: scale(1);
  }
  .start-screen__subheading{
    transition: transform 2.5s ease-in-out 0.2s, opacity 2.5s ease-in-out 0.2s;
  }
  .start-screen__subheading.last{
    transition-delay: 0.6s, 0.6s;
  }
  .start-screen.loaded .start-screen__subheading{
    transform: translateX(0%);
    opacity: 1;
  }
  .start-screen__intro{
    transition: opacity 1s ease-in-out 3s;
  }
  .start-screen.loaded .start-screen__intro{
    opacity: 1;
  }
  .start-screen__cloud{
    transform: scale(0.8);
    /*opacity: 0.9;*/
    opacity: 0.45;
    transition: transform 2s ease-in-out 0.7s, opacity 0.5s ease 2.5s;
  }
  .start-screen__cloud.top-right{
    transform-origin: top right;
  }
  .start-screen__cloud.left-bottom{
    transform-origin: left bottom;
  }
  .start-screen.loaded .start-screen__cloud.top-right{
    /*transform: translate(12.48%, -33.43%);*/
    transform: translate(50%, -50.15%) scale(1.5);
    /*animation: clouds-top-right 2s linear forwards;*/
  }
  .start-screen.loaded .start-screen__cloud.left-bottom{
    /*transform: translate(-15.28%, 46.15%);*/
    transform: translate(-50%, 69.23%) scale(1.5);
    /*animation: clouds-left-bottom 2s linear forwards;*/
  }
  .start-screen__start{
    transition: opacity 1s ease-in-out 3.5s, visibility 0s linear 3.5s;
  }
  .start-screen.loaded .start-screen__start{
    opacity: 1;
    visibility: visible;
    animation: scrolling 1.5s linear infinite;
  }
  .start-screen.loaded .start-screen__cloud.before{
    opacity: 0.9;
  }
  .start-screen.loaded .start-screen__cloud.after{
    opacity: 0;
  }
  @keyframes scrolling {
    0%{
      transform: translateY(0%);
    }
    25%{
      transform: translateY(10%);
    }
    50%{
      transform: translateY(0%);
    }
    75%{
      transform: translateY(-10%);
    }
    100%{
      transform: translateY(0%);
    }
  }

  @media(orientation: portrait){
    .start-screen__cloud.top-right{
      width: 63.7vh;
      height: 53.02vw;
    }
    .start-screen__cloud.left-bottom{
      width: 61.39vh;
      height: 62.03vw;
    }
  }

  @media(max-height: 860px){
    .start-screen__wrapper{
      padding-top: 6vh;
    }
  }
  @media(min-width: 1280px) and (max-height: 759px){
    .start-screen__wrapper{
      padding-bottom: 13vh;
    }
    .start-screen__start{
      bottom: 13vh;
    }
    .start-screen__heading{
      width: 960px;
    }
  }
  @media(max-width: 1279px){
    .start-screen__subheading{
      font-size: 2rem;
    }
    .start-screen__intro{
      font-size: 20px;
    }
  }
  @media(max-width: 767px){
    .start-screen__subheading{
      font-size: 20px;
    }
  }
  @media(max-width: 575px){
    .start-screen__wrapper{
      padding-bottom: 15vh;
    }
    .start-screen__start{
      bottom: 15vh;
    }
  }
</style>
