<template>
  <footer class="footer">
    <div class="container h-100">
      <div class="row h-100 justify-content-between align-items-center">
        <div class="col-12 col-md-auto">
          Адрес: {{address}}
        </div>
        <div class="col-12 col-md-auto" v-if="formattedPhone(phone)">
          Телефон: <a :href="`tel:+7${phone}`">{{formattedPhone(phone)}}</a><!--
          --><template v-if="email">, e-mail <a :href="`mailto:${email}`">{{email}}</a></template>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterArea",
  methods: {
    formattedPhone(phone){
      if (phone.length === 10) {
        return phone.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/g, `($1) $2-$3-$4`);
      } else {
        return null;
      }
    }
  },
  data(){
    return {
      address: '115054, Москва, Озерковская набережная д. 52а',
      phone: '4957855285', // БЕЗ кода страны!
      email: 'info@monp.ru'
    }
  }
}
</script>

<style scoped>
  .footer{
    background: rgba(255, 255, 255, 0.80);
    border-top: 1px solid #D9D9D9;
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 80;
    padding: 4px 0;
    color: #B4A990;
    font-size: 11px;
    line-height: 15px;
    letter-spacing: 0.05em;
    text-align: center;
  }
  .home + .footer,
  .error + .footer{
    position: absolute;
  }
  .footer a:hover{
    text-decoration: underline;
  }
</style>
