<template>
  <div class="stage" :class="chapter" v-if="innerDecor !== null">
    <div class="container-fluid container-xl h-100 position-relative" :class="getActiveMedia || 'base'">
      <div class="fabric-light" :class="chapter"></div>
      <template v-if="chapter === 'chapter_1'">
        <img :src="innerDecor.ch1.sketchRight" class="sketch-right" />
        <img :src="innerDecor.ch1.sketchLeft" class="sketch-left" />
        <img :src="innerDecor.ch1.clock" class="clock" />
        <div class="candle-light-small"></div>
        <div class="candle-light-big"></div>
        <img :src="innerDecor.ch1.candles" class="candles" />
        <img :src="innerDecor.ch1.inkpot" class="inkpot" />
        <img :src="innerDecor.ch1.feather" class="feather" />
        <img :src="innerDecor.ch1.lens" class="lens" />
        <div class="decorCombo base">
          <img :src="innerDecor.ch1.stamp" class="stamp" />
          <img :src="innerDecor.ch1.press" class="press" />
        </div>
        <template v-if="!checkMobile">
          <div class="decorCombo photo">
            <img :src="innerDecor.ch1.cards" class="cards" />
            <img :src="innerDecor.ch1.photocamera" class="photocamera" />
          </div>
          <div class="decorCombo docs">
            <img :src="innerDecor.ch1.letters" class="letters" />
            <img :src="innerDecor.ch1.briefcase" class="briefcase" />
          </div>
          <div class="decorCombo video">
            <img :src="innerDecor.ch1.videofilm" class="videofilm" />
            <img :src="innerDecor.ch1.videocamera" class="videocamera" />
            <img :src="innerDecor.ch1.praxinoscope" class="praxinoscope" />
          </div>
        </template>
      </template>
      <template v-else-if="chapter === 'chapter_2'">
        <img :src="innerDecor.ch2.papers" class="papers" />
        <img :src="innerDecor.ch2.folder" class="folder" />
        <div class="lamp-light-small"></div>
        <div class="lamp-light-big"></div>
        <img :src="innerDecor.ch2.lamp" class="lamp" />
        <img :src="innerDecor.ch2.watches" class="watches" />
        <img :src="innerDecor.ch2.stamp" class="stamp-2" />
        <img :src="innerDecor.ch2.badgeUSSRbig" class="badge-ussr-big" />
        <img :src="innerDecor.ch2.badgeUSSRsmall" class="badge-ussr-small" />
        <img :src="innerDecor.ch2.pen" class="pen" />
        <img :src="innerDecor.ch2.cap" class="cap" />
        <div class="decorCombo base">
          <img :src="innerDecor.ch2.phone" class="phone" />
        </div>
        <template v-if="!checkMobile">
          <div class="decorCombo photo">
            <img :src="innerDecor.ch2.filmSmall" class="film-small" />
            <img :src="innerDecor.ch2.filmBig" class="film-big" />
            <img :src="innerDecor.ch2.photocamera" class="photocamera-2" />
          </div>
          <div class="decorCombo docs">
            <img :src="innerDecor.ch2.briefcase" class="briefcase-2" />
          </div>
          <div class="decorCombo video">
            <img :src="innerDecor.ch2.videocassettes" class="videocassettes" />
          </div>
        </template>
        <img :src="innerDecor.ch2.glasses" class="glasses" />
        <img :src="innerDecor.ch2.badgeVLKSM" class="badge-vlksm" />
      </template>
      <template v-else-if="chapter === 'chapter_3'">
        <img :src="innerDecor.ch3.pad" class="pad" />
        <div class="light-small"></div>
        <div class="light-big"></div>
        <img :src="innerDecor.ch3.lamp" class="lamp-3" />
        <img :src="innerDecor.ch3.clipRight" class="clip-right" />
        <img :src="innerDecor.ch3.stapleRight" class="staple-right" />
        <img :src="innerDecor.ch3.notepad" class="notepad" />
        <img :src="innerDecor.ch3.folderGray" class="folder-gray" />
        <img :src="innerDecor.ch3.buttonRight" class="button-right" />
        <img :src="innerDecor.ch3.pen" class="pen-3" />
        <img :src="innerDecor.ch3.stamp" class="stamp-3" />
        <img :src="innerDecor.ch3.stapleLeft" class="staple-left" />
        <img :src="innerDecor.ch3.glasses" class="glasses-3" />
        <img :src="innerDecor.ch3.buttonLeft" class="button-left" />
        <div class="decorCombo base">
          <img :src="innerDecor.ch3.clipLeft" class="clip-left" />
          <img :src="innerDecor.ch3.smartphone" class="smartphone" />
        </div>
        <template v-if="!checkMobile">
          <div class="decorCombo photo">
            <img :src="innerDecor.ch3.microSD_1" class="micro-sd-0" />
            <img :src="innerDecor.ch3.photocamera" class="photocamera-3" />
          </div>
          <div class="decorCombo video">
            <img :src="innerDecor.ch3.microSD_1" class="micro-sd-1" />
            <img :src="innerDecor.ch3.microSD_2" class="micro-sd-2" />
            <img :src="innerDecor.ch3.goPro" class="go-pro" />
          </div>
          <div class="decorCombo docs">
            <img :src="innerDecor.ch3.folderBlack" class="folder-black" />
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "InnerPageDecor",
  props: ['chapter'],
  computed: {
    ...mapGetters(['getActiveMedia', 'checkMobile']),
  },
  data(){
    return{
      innerDecor: null
    }
  },
  mounted(){
    if (localStorage.getItem('innerDecor')){
      try {
        this.innerDecor = JSON.parse(localStorage.getItem('innerDecor'));
      } catch(error) {
        console.log(error);
      }
    }
  }
}
</script>

<style scoped>
  .stage{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    --duration-short: 0.5s;
    --duration-long: 1s;
  }
  .stage .container > *,
  .stage img{
    pointer-events: none;
    position: absolute;
  }
  .decorCombo{
    position: static !important;
    opacity: 0;
    transition: opacity 0.4s ease;
  }
  .base .decorCombo.base,
  .photo .decorCombo.photo,
  .video .decorCombo.video,
  .docs .decorCombo.docs{
    opacity: 1;
  }
  .fabric-light{
    position: absolute;
    top: 143px;
    left: 60px;
    width: 813px;
    height: 572px;
    border-radius: 50%;
    opacity: 0.4;
    filter: blur(387px);
  }
  .fabric-light.chapter_1{
    background: #94B0A4;
  }
  .fabric-light.chapter_2,
  .fabric-light.chapter_3{
    background: #B09494;
  }

  /* chapter 1 */
  .sketch-right{
    width: 210px;

    left: 100%;
    margin-left: 25px;
    top: 340px;
  }
  .sketch-left{
    width: 132px;

    right: 100%;
    margin-right: 120px;
    top: 464px;
  }
  .clock{
    width: 419px;
    top: 104px;
    left: 100%;
    margin-left: -72px;
  }
  .candle-light-big{
    position: absolute;
    width: 550px;
    height: 550px;
    border-radius: 50%;
    opacity: 0.3;
    background: #F05A19;
    filter: blur(387px);
    top: -275px;
    left: 100%;
    margin-left: -145px;
  }
  .candle-light-small{
    position: absolute;
    width: 333px;
    height: 333px;
    border-radius: 50%;
    opacity: 0.3;
    background: #FFE792;
    filter: blur(387px);
    top: 415px;
    margin-left: -45px;
    left: 100%;
  }
  .candles{
    width: 524px;
    top: -268px;
    left: 100%;
    margin-left: -190px;
  }
  .inkpot{
    width: 378px;
    left: 100%;
    margin-left: -360px;
    top: 600px;
  }
  .feather{
    width: 678px;
    top: 485px;
    left: 100%;
    margin-left: -384px;
  }
  .lens{
    width: 392px;
    top: 529px;
    right: 100%;
    margin-right: 62px;
  }
  .stamp{
    width: 321px;
    right: 100%;
    margin-right: 24px;
    top: 611px;
  }
  .press{
    width: 336px;
    top: 179px;
    right: 100%;
    margin-right: 60px;
  }
  .cards{
    width: 324px;
    bottom: -202px;
    margin-right: 50px;
    right: 100%;
  }
  .photocamera{
    width: 624px;
    top: 123px;
    right: 100%;
    margin-right: 77px;
  }
  .letters{
    width: 274px;
    bottom: -148px;
    right: 100%;
    margin-right: 35px;
  }
  .briefcase{
    width: 665px;
    top: 156px;
    right: 100%;
    margin-right: 52px;
  }
  .videofilm{
    width: 391px;
    right: 100%;
    margin-right: 4px;
    top: 84px;
  }
  .videocamera{
    width: 511px;
    top: 95px;
    right: 100%;
    margin-right: 28px;
  }
  .praxinoscope{
    width: 388px;
    bottom: -265px;
    right: 100%;
    margin-right: -10px;
  }

  /* animation ch.1 */
  .sketch-right,
  .sketch-left{
    opacity: 0;
    transition: opacity var(--duration-short) ease 0s;
  }
  .inkpot{
    opacity: 0;
    transform: translate(50%, 0%);
    transition: opacity var(--duration-short) ease 0.1s, transform var(--duration-long) ease 0.1s;
  }
  .feather{
    opacity: 0;
    transform: translate(50%, 0%);
    transition: opacity var(--duration-short) ease 0.2s, transform var(--duration-long) ease 0.2s;
  }
  .clock{
    opacity: 0;
    transform: translate(50%, 0%);
    transition: opacity var(--duration-short) ease 0.3s, transform var(--duration-long) ease 0.3s;
  }
  .candle-light-big,
  .candle-light-small,
  .candles{
    opacity: 0;
    transform: translate(50%, 0%);
    transition: opacity var(--duration-short) ease 0.4s, transform var(--duration-long) ease 0.4s;
  }

  .stamp{
    opacity: 0;
    transform: translate(-50%, 0%);
    transition: opacity var(--duration-short) ease 0.1s, transform var(--duration-long) ease 0.1s;
  }
  .lens{
    opacity: 0;
    transform: translate(-50%, 0%);
    transition: opacity var(--duration-short) ease 0.2s, transform var(--duration-long) ease 0.2s;
  }
  .press{
    opacity: 0;
    transform: translate(-50%, 0%);
    transition: opacity var(--duration-short) ease 0.35s, transform var(--duration-long) ease 0.35s;
  }

  .passed .sketch-right,
  .passed .sketch-left{
    opacity: 1;
  }

  .passed .inkpot,
  .passed .feather,
  .passed .clock,
  .passed .candles,
  .passed .stamp,
  .passed .lens,
  .passed .press{
    opacity: 1;
    transform: translate(0%, 0%);
  }
  .passed .candle-light-big,
  .passed .candle-light-small{
    opacity: 0.3;
    transform: translate(0%, 0%);
  }

  /* chapter 2 */
  .papers{
    width: 565px;
    bottom: -69px;
    left: 100%;
    margin-left: -53px;
  }
  .folder{
    width: 464px;
    left: 100%;
    margin-left: -86px;
    bottom: -158px;
  }
  .lamp-light-small{
    position: absolute;
    width: 290px;
    height: 273px;
    border-radius: 50%;
    opacity: 0.3;
    background: rgba(254, 231, 149, 0.50);
    filter: blur(24px);
    margin-left: 55px;
    left: 100%;
    top: -95px;
  }
  .lamp-light-big{
    position: absolute;
    width: 468px;
    height: 468px;
    opacity: 0.3;
    border-radius: 50%;
    background: rgba(240, 90, 25, 0.53);
    filter: blur(53px);
    left: 100%;
    margin-left: -37px;
    top: -190px;
  }
  .lamp{
    width: 348px;
    left: 100%;
    margin-left: 10px;
    top: -190px;
  }
  .watches{
    width: 310px;
    left: 100%;
    margin-left: 50px;
    top: 56px;
  }
  .stamp-2{
    width: 132px;
    right: 206px;
    bottom: -4px;
  }
  .badge-ussr-big{
    width: 86px;
    right: 112px;
    bottom: -42px;
  }
  .badge-ussr-small{
    width: 75px;
    left: 100%;
    margin-left: 202px;
    bottom: 175px;
  }
  .pen{
    width: 200px;
    bottom: 38px;
    right: 83px;
  }
  .cap{
    width: 52px;
    bottom: 25px;
    left: 100%;
    margin-left: 63px;
  }
  .phone{
    width: 472px;
    top: 220px;
    right: 100%;
    margin-right: 45px;
  }
  .glasses{
    width: 331px;
    right: 100%;
    margin-right: 18px;
    bottom: -45px;
  }
  .badge-vlksm{
    width: 73px;
    bottom: -31px;
    right: 100%;
    margin-right: 45px;
  }
  .film-small{
    width: 170px;
    right: 100%;
    margin-right: 98px;
    top: 465px;
  }
  .film-big{
    width: 185px;
    top: 544px;
    right: 100%;
    margin-right: 168px;
  }
  .photocamera-2{
    width: 346px;
    top: 204px;
    margin-right: 66px;
    right: 100%;
  }
  .briefcase-2{
    width: 657px;
    margin-right: -15px;
    right: 100%;
    top: 110px;
  }
  .videocassettes{
    width: 510px;
    top: 135px;
    right: 100%;
    margin-right: 10px;
  }

  /* animation ch.2 */
  .phone{
    opacity: 0;
    transform: translate(-50%, 0%);
    transition: opacity var(--duration-short) ease 0.1s, transform var(--duration-long) ease 0.1s;
  }
  .glasses{
    opacity: 0;
    transform: translate(-50%, 0%);
    transition: opacity var(--duration-short) ease 0.3s, transform var(--duration-long) ease 0.3s;
  }
  .badge-vlksm{
    opacity: 0;
    transform: translate(-100%, 0%);
    transition: opacity var(--duration-short) ease 0.5s, transform var(--duration-long) ease 0.5s;
  }
  .lamp-light-small,
  .lamp-light-big,
  .lamp{
    opacity: 0;
    transform: translate(50%, 0%);
    transition: opacity var(--duration-short) ease 0.1s, transform var(--duration-long) ease 0.1s;
  }
  .papers{
    opacity: 0;
    transform: translate(50%, 0%);
    transition: opacity var(--duration-short) ease 0.2s, transform var(--duration-long) ease 0.2s;
  }
  .badge-ussr-big{
    opacity: 0;
    transform: translate(100%, 0%);
    transition: opacity var(--duration-short) ease 0.35s, transform var(--duration-long) ease 0.35s;
  }
  .folder{
    opacity: 0;
    transform: translate(50%, 0%);
    transition: opacity var(--duration-short) ease 0.3s, transform var(--duration-long) ease 0.3s;
  }
  .watches{
    opacity: 0;
    transform: translate(50%, 0%);
    transition: opacity var(--duration-short) ease 0.4s, transform var(--duration-long) ease 0.4s;
  }
  .stamp-2{
    opacity: 0;
    transform: translate(50%, 0%);
    transition: opacity var(--duration-short) ease 0.35s, transform var(--duration-long) ease 0.35s;
  }
  .pen{
    opacity: 0;
    transform: translate(50%, 0%);
    transition: opacity var(--duration-short) ease 0.5s, transform var(--duration-long) ease 0.5s;
  }
  .cap{
    opacity: 0;
    transform: translate(100%, 0%);
    transition: opacity var(--duration-short) ease 0.5s, transform var(--duration-long) ease 0.5s;
  }
  .badge-ussr-small{
    opacity: 0;
    transform: translate(100%, 0%);
    transition: opacity var(--duration-short) ease 0.65s, transform var(--duration-long) ease 0.65s;
  }

  .passed .phone,
  .passed .glasses,
  .passed .badge-ussr-big,
  .passed .badge-ussr-small,
  .passed .badge-vlksm,
  .passed .lamp,
  .passed .papers,
  .passed .folder,
  .passed .pen,
  .passed .cap,
  .passed .stamp-2,
  .passed .watches{
    opacity: 1;
    transform: translate(0%, 0%);
  }
  .passed .lamp-light-big,
  .passed .lamp-light-small{
    opacity: 0.3;
    transform: translate(0%, 0%);
  }

  /* chapter 3 */
  .pad{
    width: 1552px;
    bottom: 0;
    left: -68px;
  }
  .light-small{
    position: absolute;
    width: 291px;
    height: 274px;
    border-radius: 50%;
    background: rgba(210, 191, 120, 0.50);
    filter: blur(24px);
    top: -96px;
    left: 100%;
    margin-left: 53px;
  }
  .light-big{
    position: absolute;
    width: 470px;
    height: 470px;
    border-radius: 50%;
    opacity: 0.3;
    background: rgba(203, 165, 149, 0.53);
    filter: blur(53px);
    top: -190px;
    left: 100%;
    margin-left: -40px;
  }
  .lamp-3{
    width: 620px;
    top: -184px;
    left: 100%;
    margin-left: 25px;
  }
  .clip-right{
    width: 88px;
    top: 165px;
    left: 100%;
    margin-left: 119px;
  }
  .staple-right{
    width: 90px;
    top: 225px;
    left: 100%;
    margin-left: 214px;
  }
  .notepad{
    width: 324px;
    margin-left: 9px;
    left: 100%;
    top: 319px;
  }
  .button-right{
    width: 52px;
    bottom: 145px;
    left: 100%;
    margin-left: 158px;
  }
  .folder-gray{
    width: 694px;
    right: -175px;
    bottom: -415px;
  }
  .stamp-3{
    width: 312px;
    margin-left: 12px;
    left: 100%;
    bottom: -109px;
  }
  .pen-3{
    right: 70px;
    bottom: 66px;
    width: 217px;
  }
  .staple-left{
    width: 100px;
    margin-right: 89px;
    right: 100%;
    bottom: -43px;
  }
  .glasses-3{
    width: 320px;
    bottom: -21px;
    right: 100%;
    margin-right: 50px;
  }
  .button-left{
    width: 53px;
    margin-right: 110px;
    right: 100%;
    bottom: 298px;
  }
  .clip-left{
    width: 108px;
    bottom: 224px;
    right: 100%;
    margin-right: 171px;
  }
  .smartphone{
    width: 282px;
    top: 168px;
    right: 100%;
    margin-right: 103px;
  }
  .micro-sd-0{
    width: 82px;
    bottom: 204px;
    margin-right: 178px;
    right: 100%;
  }
  .photocamera-3{
    width: 471px;
    margin-right: 15px;
    right: 100%;
    top: 141px;
  }
  .micro-sd-1{
    width: 82px;
    bottom: 343px;
    margin-right: 106px;
    right: 100%;
  }
  .micro-sd-2{
    width: 76px;
    margin-right: 217px;
    right: 100%;
    bottom: 220px;
  }
  .go-pro{
    width: 249px;
    right: 100%;
    margin-right: 114px;
    top: 203px;
  }
  .folder-black{
    margin-right: 115px;
    right: 100%;
    top: 112px;
    width: 549px;
  }

  /* animation ch.3 */
  .pad{
    opacity: 0;
    transform: translate(0, 50%);
    transition: opacity var(--duration-short) ease 0s, transform var(--duration-long) ease 0s;
  }
  .folder-gray{
    opacity: 0;
    transform: translate(50%, 0);
    transition: opacity var(--duration-short) ease 0.1s, transform var(--duration-long) ease 0.1s;
  }
  .button-right{
    opacity: 0;
    transform: translate(100%, 0);
    transition: opacity var(--duration-short) ease 0.2s, transform var(--duration-long) ease 0.2s;
  }
  .stamp-3{
    opacity: 0;
    transform: translate(50%, 0);
    transition: opacity var(--duration-short) ease 0.25s, transform var(--duration-long) ease 0.25s;
  }
  .pen-3{
    opacity: 0;
    transform: translate(50%, 0);
    transition: opacity var(--duration-short) ease 0.4s, transform var(--duration-long) ease 0.4s;
  }
  .notepad {
    opacity: 0;
    transform: translate(50%, 0);
    transition: opacity var(--duration-short) ease 0.5s, transform var(--duration-long) ease 0.5s;
  }
  .clip-right{
    opacity: 0;
    transform: translate(50%, 0);
    transition: opacity var(--duration-short) ease 0.2s, transform var(--duration-long) ease 0.2s;
  }
  .staple-right{
    opacity: 0;
    transform: translate(50%, 0);
    transition: opacity var(--duration-short) ease 0.1s, transform var(--duration-long) ease 0.1s;
  }
  .light-small,
  .light-big,
  .lamp-3{
    opacity: 0;
    transform: translate(50%, 0);
    transition: opacity var(--duration-short) ease 0.6s, transform var(--duration-long) ease 0.6s;
  }

  .staple-left{
    opacity: 0;
    transform: translate(-50%, 0);
    transition: opacity var(--duration-short) ease 0.1s, transform var(--duration-long) ease 0.1s;
  }
  .glasses-3{
    opacity: 0;
    transform: translate(-50%, 0);
    transition: opacity var(--duration-short) ease 0.2s, transform var(--duration-long) ease 0.2s;
  }
  .button-left{
    opacity: 0;
    transform: translate(-100%, 0);
    transition: opacity var(--duration-short) ease 0.3s, transform var(--duration-long) ease 0.3s;
  }
  .clip-left{
    opacity: 0;
    transform: translate(-50%, 0);
    transition: opacity var(--duration-short) ease 0.25s, transform var(--duration-long) ease 0.25s;
  }
  .smartphone{
    opacity: 0;
    transform: translate(-50%, 0);
    transition: opacity var(--duration-short) ease 0.4s, transform var(--duration-long) ease 0.4s;
  }

  .passed .pad,
  .passed .folder-gray,
  .passed .stamp-3,
  .passed .pen-3,
  .passed .button-right,
  .passed .notepad,
  .passed .staple-right,
  .passed .clip-right,
  .passed .lamp-3,
  .passed .staple-left,
  .passed .button-left,
  .passed .clip-left,
  .passed .glasses-3,
  .passed .smartphone{
    opacity: 1;
    transform: translate(0%, 0%);
  }
  .passed .light-small,
  .passed .light-big{
    opacity: 0.3;
    transform: translate(0%, 0%);
  }

  @media(max-width: 1279px){
    .decorCombo.base{
      opacity: 1 !important;
    }
    .decorCombo.photo,
    .decorCombo.video,
    .decorCombo.docs{
      display: none;
    }

    .candle-light-small,
    .candle-light-big,
    .inkpot,
    .feather,
    .stamp,
    .stamp-2,
    .badge-ussr-big,
    .badge-ussr-small,
    .badge-vlksm,
    .pad,
    .folder-gray,
    .button-right,
    .pen-3,
    .stamp-3{
      display: none;
    }

    .fabric-light{
      top: 125px;
      left: 0;
      height: 100vw;
      width: 100vw;
    }
    .sketch-right{
      width: 205px;
      margin-left: 0;
      left: auto;
      right: -70px;
      top: 700px;
    }
    .clock{
      width: 210px;
      left: auto;
      margin-left: 0;
      right: -64px;
      top: 450px;
    }
    .candles{
      top: 205px;
      left: auto;
      margin-left: 0;
      width: 262px;
      right: -200px;
    }
    .sketch-left{
      right: auto;
      margin-right: 0;
      width: 67px;
      left: -35px;
      top: 355px;
    }
    .lens{
      right: auto;
      margin-right: 0;
      width: 196px;
      left: -135px;
      top: 467px;
    }
    .press{
      right: auto;
      margin-right: 0;
      width: 168px;
      top: 150px;
      left: -138px;
    }

    .papers{
      width: 192px;
      bottom: auto;
      left: auto;
      margin-left: 0;
      right: -128px;
      top: 515px;
    }
    .folder{
      width: 175px;
      left: auto;
      margin-left: 0;
      bottom: auto;
      right: -90px;
      top: 574px;
    }
    .lamp-light-small{
      width: 110px;
      height: 103px;
      margin-left: 0;
      left: auto;
      right: -90px;
      top: 324px;
    }
    .lamp-light-big{
      width: 177px;
      height: 177px;
      left: auto;
      margin-left: 0;
      top: 300px;
      right: -123px;
    }
    .lamp{
      width: 131px;
      left: auto;
      margin-left: 0;
      top: 300px;
      right: -96px;
    }
    .watches{
      width: 117px;
      left: auto;
      margin-left: 0;
      top: 450px;
      right: -50px;
    }
    .pen{
      width: 75px;
      bottom: auto;
      right: 2px;
      top: 628px;
    }
    .cap{
      width: 20px;
      bottom: auto;
      left: auto;
      margin-left: 0;
      right: -5px;
      top: 608px;
    }
    .phone{
      width: 178px;
      top: 124px;
      right: auto;
      margin-right: 0;
      left: -145px;
    }
    .glasses{
      width: 125px;
      right: auto;
      margin-right: 0;
      bottom: auto;
      left: -43px;
      top: 507px;
    }

    .light-small{
      width: 122px;
      height: 114px;
      top: 338px;
      left: auto;
      margin-left: 0;
      right: -103px;
    }
    .light-big{
      width: 196px;
      height: 196px;
      top: 299px;
      left: auto;
      margin-left: 0;
      right: -140px;
    }
    .lamp-3{
      width: 258px;
      top: 302px;
      left: auto;
      margin-left: 0;
      right: -228px;
    }
    .clip-right{
      width: 36px;
      top: 491px;
      left: auto;
      margin-left: 0;
      right: -8px;
    }
    .staple-right{
      width: 37px;
      top: 548px;
      left: auto;
      margin-left: 0;
      right: -17px;
    }
    .notepad{
      width: 140px;
      margin-left: 0;
      left: auto;
      top: 589px;
      right: -80px;
    }
    .staple-left{
      width: 41px;
      margin-right: 0;
      right: auto;
      bottom: auto;
      left: 4px;
      top: 588px;
    }
    .glasses-3{
      width: 133px;
      bottom: auto;
      right: auto;
      margin-right: 0;
      left: -70px;
      top: 510px;
    }
    .button-left{
      width: 22px;
      margin-right: 0;
      right: auto;
      bottom: auto;
      left: -1px;
      top: 422px;
    }
    .clip-left{
      width: 45px;
      bottom: auto;
      margin-right: 0;
      right: auto;
      top: 330px;
      left: -14px;
    }
    .smartphone{
      width: 130px;
      top: 109px;
      right: auto;
      margin-right: 0;
      left: -94px;
    }
  }
</style>
