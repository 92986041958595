<template>
  <header class="header" :class="{'expanded': menuExpanded}">
    <div class="container h-100">
      <div class="row h-100 justify-content-between align-items-center">
        <div class="col col-xl-auto">
          <!--
          <router-link
              to="/"
              @click.native="setActiveMedia(); menuExpanded = false; setCurrentChapter(1)"
              v-if="$route.path !== '/'"
          >
            <img class="header-logo" :src="mainLogo" />
          </router-link>
          <img v-else class="header-logo" :src="mainLogo" />
          -->
          <a href="http://www.monp.ru/" target="_blank">
            <img class="header-logo" :src="mainLogo" />
          </a>
        </div>
        <div class="col-auto d-none d-xl-block">
          <ul class="header-menu">
            <li v-for="item in pageChapters">
              <router-link
                  :class="{'active': item.num === getCurrentChapter}"
                  @click.native="setActiveMedia(); menuExpanded = false; setCurrentChapter(item.num)"
                  :to="{path: '/', hash: `#${item.code}`}"
              >Глава {{item.num}}</router-link>
            </li>
          </ul>
        </div>
        <div class="col col-xl-auto">
          <img class="header-decor" :src="decorSVG" />
        </div>
        <div class="col-auto d-none d-xl-block">
          <ul class="header-menu">
            <li v-for="page in innerPages">
              <router-link
                  @click.native="setActiveMedia(); menuExpanded = false; setCurrentChapter(0)"
                  active-class="active"
                  :to="`/${page.code}`"
              >{{page.text}}</router-link>
            </li>
          </ul>
        </div>
        <div class="col d-xl-none">
          <a class="header-menu-burger" @click="menuExpanded = !menuExpanded"><span></span></a>
        </div>
      </div>
    </div>
    <div class="header-mobile d-xl-none">
      <div class="header-mobile__menu">
        <div class="container">
          <ul class="header-menu">
            <li v-for="item in pageChapters">
              <router-link
                  :class="{'active': item.num === getCurrentChapter}"
                  @click.native="setActiveMedia(); updateMenu(item.num)"
                  :to="{path: '/', hash: `#${item.code}`}"
              >Глава {{item.num}}</router-link>
            </li>
          </ul>
          <ul class="header-menu">
            <li v-for="page in innerPages">
              <router-link
                  @click.native="setActiveMedia(); updateMenu(0)"
                  active-class="active"
                  :to="`/${page.code}`"
              >{{page.text}}</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: "HeaderArea",
  computed: {
    ...mapGetters(['getCurrentChapter']),
  },
  methods: {
    ...mapActions(['setCurrentChapter', 'expandTimeline', 'setActiveMedia']),
    updateMenu(chapter){
      this.expandTimeline(false);
      this.menuExpanded = false;
      this.setCurrentChapter(chapter);
    },
    async getMenu(){
      this.$axios
          .get('api/sections/tree/')
          .then(response => {
            const finalRes = response.data.sideTitles;
            for (let i=0; i< finalRes.length; i++){
              this.pageChapters[i].code = finalRes[i].itemId;
            }
          })
          .catch(error => {
            console.log(error);
          });
    }
  },
  data(){
    return {
      menuExpanded: false,
      decorSVG: require('@/static/img/header-decor.svg'),
      mainLogo: require('@/static/img/main-logo.png'),
      pageChapters: [
        {
          num: 1,
          code: ''
        },
        {
          num: 2,
          code: ''
        },
        {
          num: 3,
          code: ''
        }
      ],
      innerPages: [
        {
          code: 'about',
          text: 'О проекте'
        },
        {
          code: 'photos',
          text: 'Все фото'
        },
        {
          code: 'videos',
          text: 'Все видео'
        },
        {
          code: 'documents',
          text: 'Все документы'
        },
        {
          code: 'feedback',
          text: 'Задать вопрос'
        }
      ]
    }
  },
  mounted() {
    this.getMenu();
  }
}
</script>

<style scoped>
  .header{
    height: 3rem;
    background: #fff;
    border-bottom: 1px solid #D9D9D9;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 90;
  }
  .header-logo{
    max-width: 4.3rem;
  }
  .header-decor{
    display: block;
    width: 7.8rem;
    height: 2rem;
    margin: 0 auto;
  }
  .header-menu{
    font-size: 0;
    color: #B4A990;
  }
  .header-menu li{
    display: inline-block;
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 0.05em;
    margin-left: 1.8rem;
  }
  .header-menu li:first-child{
    margin-left: 0;
  }
  .header-menu a{
    cursor: pointer;
  }
  .header-menu a:hover{
    color: #5A5037;
  }
  .header-menu .active{
    color: #B82F2F !important;
  }

  .header-menu-burger{
    display: block;
    margin: 0 0 0 auto;
    width: 28px;
    height: 28px;
    cursor: pointer;
    position: relative;
  }
  .header-menu-burger:before,
  .header-menu-burger:after,
  .header-menu-burger span{
    background: #B4A990;
    width: 100%;
    height: 2px;
    margin-top: -1px;
    position: absolute;
    left: 50%;
    margin-left: -14px;
    transition: all 0.3s ease;
  }
  .header-menu-burger:before{
    content: '';
    top: 16%;
  }
  .header-menu-burger:after{
    content: '';
    top: 86%;
  }
  .header-menu-burger span{
    top: 50%;
  }
  .header.expanded .header-menu-burger span{
    opacity: 0;
  }
  .header.expanded .header-menu-burger:before{
    transform: rotate(45deg);
    top: 50%;
  }
  .header.expanded .header-menu-burger:after{
    transform: rotate(-45deg);
    top: 50%;
  }

  .header-mobile{
    transition: height 0.5s ease, margin-top 0s ease 0.5s;
    height: 0;
    overflow: hidden;
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    background: #fff;
  }
  .header-mobile__menu{
    padding: 2.4rem 15px 1px;
  }
  .header-mobile__menu .header-menu li{
    margin-bottom: 26px;
    font-size: 19px;
  }
  .header.expanded .header-mobile{
    margin-top: 1px;
    overflow: auto;
    height: calc(100vh - 3rem);
    transition: height 0.5s ease, margin-top 0s ease 0s;
  }
  .header-mobile__menu .header-menu li{
    margin-left: 0;
    display: block;
  }

  @media(max-width: 767px){
    .header-decor{
      width: 86px;
      height: 22px;
    }
  }
  @media(max-width: 575px){
    .header{
      height: 48px;
    }
    .header-logo{
      max-width: 58px;
    }
    .header-menu-burger{
      width: 18px;
      height: 18px;
    }
    .header-menu-burger:before,
    .header-menu-burger:after,
    .header-menu-burger span{
      height: 1px;
      margin-left: -9px;
    }
  }
</style>
