<template>
  <div class="page">
    <template v-if="isFirstVisit">
      <PreloaderProgress :loaded="isLoadedStatus" :percents="progressPercentsCurrent" />
      <StartScreen :loaded="isLoadedStatus"/>
    </template>
    <PreloaderCommon v-else :loaded="isPreloaded" />

    <HeaderArea />
    <router-view/>
    <FooterArea />
  </div>
</template>

<script>
import HeaderArea from "@/components/HeaderArea";
import PreloaderProgress from "@/components/PreloaderProgress";
import StartScreen from "@/components/StartScreen";
import FooterArea from "@/components/FooterArea";
import { mapGetters, mapActions } from 'vuex';
import PreloaderCommon from "@/components/PreloaderCommon";

export default {
  components: {PreloaderCommon, FooterArea, StartScreen, PreloaderProgress, HeaderArea},
  computed: {
    ...mapGetters(['isFirstVisit', 'isPreloaded', 'checkMobile']),
    isLoadedStatus(){
      return this.isLoaded;
    },
    progressPercentsCurrent(){
      return this.progressPercents;
    }
  },
  methods:{
    ...mapActions(['setPreloaderStatus', 'setMobile']),
    isMobile(){
      this.setMobile(window.innerWidth < 1280);
      let newSize = this.checkMobile;
      if (this.mobile !== newSize){
        window.location.reload(); // для обновления главного слайдера
      }
    },
    pseudoLoading(){
      let deltaTime = this.timeEnd / 100;
      let timerId = setInterval(() => {
        this.progressPercents ++;
        this.timeStart += deltaTime;
        if (this.progressPercents === this.preloadPercents) {
          clearInterval(timerId);
          if (document.readyState === 'complete') {
            this.pseudoLoading();
          } else {
            window.addEventListener('load', this.pseudoLoading);
          }
        }
        if (this.progressPercents === 100) {
          clearInterval(timerId);
          this.isLoaded = true;
        }
      },this.timeEnd*0.01);
    }
  },
  watch: {
    '$route.path'() {
      if (!this.isFirstVisit){
        this.setPreloaderStatus(false);
      }
    }
  },
  data(){
    return{
      preloadPercents: 50,
      progressPercents: 0,
      isLoaded: false,
      timeStart: 0,
      timeEnd: 3000,
      titleDecor: {
        ch1: {
          paperTop: require('@/components/img/chapter-1/paper-top.png'),
          winterPalace: require('@/components/img/chapter-1/winter-palace.png'),
          paint: require('@/components/img/chapter-1/paint.png'),
          senatePalace: require('@/components/img/chapter-1/senate-palace.png'),
          gravure: require('@/components/img/chapter-1/gravure.png'),
          churchTrees: require('@/components/img/chapter-1/church-trees.png'),
          lantern: require('@/components/img/chapter-1/lantern.png'),
          carriage: require('@/components/img/chapter-1/carriage.png'),
          ship: require('@/components/img/chapter-1/ship.png'),
          wharf: require('@/components/img/chapter-1/wharf.png'),
          peter: require('@/components/img/chapter-1/peter.png'),
          paperBack: require('@/components/img/bg-footer.png'),
          fenceBack: require('@/components/img/chapter-1/fence-back.png'),
          fenceFront: require('@/components/img/chapter-1/fence-front.png'),
          crown: require('@/components/img/chapter-1/crown.png'),
          alexander: require('@/components/img/chapter-1/alexander.png'),
          deacons: require('@/components/img/chapter-1/deacons.png'),
          birds: require('@/components/img/chapter-1/birds.png'),
        },
        ch2:{
          pavilion: require('@/components/img/chapter-2/pavilion.png'),
          clouds: require('@/components/img/chapter-2/clouds.png'),
          gosplan: require('@/components/img/chapter-2/gosplan.png'),
          mid: require('@/components/img/chapter-2/mid.png'),
          revolution: require('@/components/img/chapter-2/revolution.png'),
          paperBottom: require('@/components/img/chapter-2/paper-bottom.png'),
          tram: require('@/components/img/chapter-2/tram.png'),
          wires: require('@/components/img/chapter-2/wires.svg'),
          trafficLights: require('@/components/img/chapter-2/traffic-lights.png'),
          lenin: require('@/components/img/chapter-2/lenin.png'),
          flag: require('@/components/img/chapter-2/flag.png'),
          rocket: require('@/components/img/chapter-2/rocket.png'),
          children: require('@/components/img/chapter-2/children.png'),
          sputnik: require('@/components/img/chapter-2/sputnik.png'),
        },
        ch3:{
          bgSky: require('@/components/img/chapter-3/sky.jpg'),
          plane: require('@/components/img/chapter-3/plane.png'),
          builtinsBridge: require('@/components/img/chapter-3/buildings-bridge.png'),
          builtinFlag: require('@/components/img/chapter-3/building-flag.png'),
          archTrees: require('@/components/img/chapter-3/arch-trees.png'),
          bridge: require('@/components/img/chapter-3/bridge.png'),
          monp: require('@/components/img/chapter-3/monp.png'),
          monastery: require('@/components/img/chapter-3/monastery.png'),
          treesBig: require('@/components/img/chapter-3/trees-1.png'),
          roadsRiver: require('@/components/img/chapter-3/roads-river.png'),
          boat: require('@/components/img/chapter-3/boat.png'),
          robot: require('@/components/img/chapter-3/robot.png'),
          treesSmall: require('@/components/img/chapter-3/trees-2.png'),
          tram: require('@/components/img/chapter-3/tram.png'),
          roads: require('@/components/img/chapter-3/roads.png'),
          paperBottom: require('@/components/img/chapter-3/paper-bottom.png'),
          people: require('@/components/img/chapter-3/people.png'),
        }
      },
      innerDecor: {
        ch1: {
          sketchRight: require('@/components/img/chapter-1/sketch-right.png'),
          sketchLeft: require('@/components/img/chapter-1/sketch-left.png'),
          candles: require('@/components/img/chapter-1/candles.png'),
          clock: require('@/components/img/chapter-1/clock.png'),
          inkpot: require('@/components/img/chapter-1/inkpot.png'),
          feather: require('@/components/img/chapter-1/feather.png'),
          lens: require('@/components/img/chapter-1/lens.png'),
          stamp: require('@/components/img/chapter-1/stamp.png'),
          press: require('@/components/img/chapter-1/press.png'),
          cards: require('@/components/img/chapter-1/cards.png'),
          photocamera: require('@/components/img/chapter-1/photocamera.png'),
          letters: require('@/components/img/chapter-1/letters.png'),
          briefcase: require('@/components/img/chapter-1/briefcase.png'),
          videofilm: require('@/components/img/chapter-1/videofilm.png'),
          videocamera: require('@/components/img/chapter-1/videocamera.png'),
          praxinoscope: require('@/components/img/chapter-1/praxinoscope.png'),
        },
        ch2: {
          papers: require('@/components/img/chapter-2/papers.png'),
          folder: require('@/components/img/chapter-2/folder.png'),
          lamp: require('@/components/img/chapter-2/lamp.png'),
          watches: require('@/components/img/chapter-2/watches.png'),
          stamp: require('@/components/img/chapter-2/stamp.png'),
          badgeUSSRbig: require('@/components/img/chapter-2/badge-ussr-big.png'),
          badgeUSSRsmall: require('@/components/img/chapter-2/badge-ussr-small.png'),
          pen: require('@/components/img/chapter-2/pen.png'),
          cap: require('@/components/img/chapter-2/cap.png'),
          badgeVLKSM: require('@/components/img/chapter-2/badge-vlksm.png'),
          phone: require('@/components/img/chapter-2/phone.png'),
          glasses: require('@/components/img/chapter-2/glasses.png'),
          filmSmall: require('@/components/img/chapter-2/film-small.png'),
          filmBig: require('@/components/img/chapter-2/film-big.png'),
          photocamera: require('@/components/img/chapter-2/photocamera.png'),
          briefcase: require('@/components/img/chapter-2/briefcase.png'),
          videocassettes: require('@/components/img/chapter-2/videocassettes.png'),
        },
        ch3:{
          pad: require('@/components/img/chapter-3/pad.png'),
          lamp: require('@/components/img/chapter-3/lamp.png'),
          clipRight: require('@/components/img/chapter-3/clip-right.png'),
          stapleRight: require('@/components/img/chapter-3/staple-right.png'),
          notepad: require('@/components/img/chapter-3/notepad.png'),
          buttonRight: require('@/components/img/chapter-3/button-right.png'),
          folderGray: require('@/components/img/chapter-3/folder-gray.png'),
          stamp: require('@/components/img/chapter-3/stamp.png'),
          pen: require('@/components/img/chapter-3/pen.png'),
          stapleLeft: require('@/components/img/chapter-3/staple-left.png'),
          glasses: require('@/components/img/chapter-3/glasses.png'),
          clipLeft: require('@/components/img/chapter-3/clip-left.png'),
          buttonLeft: require('@/components/img/chapter-3/button-left.png'),
          smartphone: require('@/components/img/chapter-3/smartphone.png'),
          microSD_1: require('@/components/img/chapter-3/micro-sd-1.png'),
          microSD_2: require('@/components/img/chapter-3/micro-sd-2.png'),
          goPro: require('@/components/img/chapter-3/go-pro.png'),
          folderBlack: require('@/components/img/chapter-3/folder-black.png'),
          photocamera: require('@/components/img/chapter-3/photocamera.png'),
        }
      }
    }
  },
  beforeCreate() {
    // localStorage.clear();
  },
  created() {
    if (!localStorage.getItem('titleDecor')){
      const titleDecorParsed = JSON.stringify(this.titleDecor);
      localStorage.setItem('titleDecor', titleDecorParsed);
      // console.log('Титульный декор в хранилище');
    }
    if (!localStorage.getItem('innerDecor')){
      const innerDecorParsed = JSON.stringify(this.innerDecor);
      localStorage.setItem('innerDecor', innerDecorParsed);
      // console.log('Остальной декор в хранилище');
    }
  },
  mounted() {
    this.pseudoLoading();
    window.addEventListener('resize', this.isMobile);
    window.addEventListener('orientationchange', this.isMobile);
    this.mobile = this.checkMobile;
  },
  unmounted() {
    window.removeEventListener('load', this.pseudoLoading);
    window.removeEventListener('resize', this.isMobile);
    window.removeEventListener('orientationchange', this.isMobile);
  }
}
</script>
