<template>
  <div class="media-popup">
    <a class="media-popup__close" @click="closeMedia"></a>
    <div class="media-popup__title">{{getButtonText(type)}}</div>
    <div
        class="media-slider"
        :class="`type-${type}`"
    >
      <template v-if="type === 'photo'">
          <swiper
              :modules="modules"
              :slides-per-view="1"
              :slides-per-group="1"
              :breakpoints="{576: {slidesPerView: 2}, 768: {slidesPerView: 3}}"
              :navigation="{nextEl: '.media-slider .arrow-next', prevEl: '.media-slider .arrow-prev'}"
          >
            <swiper-slide v-for="item in content.content">
              <a class="item" @click="modalContent = item; modalOpen = true">
                <div class="media-slider__photo">
                  <div class="media-slider__photo-wrapper">
                    <picture>
                      <img :src="baseUrl + item.src" alt="" />
                    </picture>
                  </div>
                </div>
                <div class="media-slider__info" :title="item.info">{{item.info}}</div>
              </a>
            </swiper-slide>
          </swiper>
      </template>
      <template v-else-if="type === 'video'">
        <swiper
            :modules="modules"
            :slides-per-view="1"
            :slides-per-group="1"
            :breakpoints="{768: {slidesPerView: 2}}"
            :navigation="{nextEl: '.media-slider .arrow-next', prevEl: '.media-slider .arrow-prev'}"
        >
          <swiper-slide v-for="item in content.content">
            <a class="item" @click="modalContent = item; modalOpen = true">
              <div class="media-slider__photo">
                <div class="media-slider__photo-wrapper">
                  <picture>
                    <img :src="`https://img.youtube.com/vi/${item.src}/mqdefault.jpg`" alt="" />
                  </picture>
                </div>
              </div>
              <div class="media-slider__info" :title="item.info">{{item.info}}</div>
            </a>
          </swiper-slide>
        </swiper>
      </template>
      <template v-else-if="type === 'docs'">
        <swiper
            :modules="modules"
            :observer="true"
            :observe-parents="true"
            :observe-slide-children="true"
            :slides-per-view="1"
            :breakpoints="{576: {slidesPerView: 2}, 768: {slidesPerView: 3}, 992: {slidesPerView: 4}}"
            :slides-per-group="1"
            :navigation="{nextEl: '.media-slider .arrow-next', prevEl: '.media-slider .arrow-prev'}"
        >
          <swiper-slide v-for="item in content.content">
            <a class="item" @click="modalContent = item; modalOpen = true">
              <div class="media-slider__photo">
                <div class="media-slider__photo-wrapper">
                  <picture>
                    <img :src="baseUrl + item.src" alt="" />
                  </picture>
                </div>
              </div>
              <div class="media-slider__info" :title="item.info">{{item.info}}</div>
            </a>
          </swiper-slide>
        </swiper>
      </template>
      <template v-else>
        Некорректный тип медиа
      </template>
      <div class="arrow-prev"></div>
      <div class="arrow-next"></div>
    </div>

    <Teleport to="body">
      <Transition name="modal">
        <ModalWindow v-if="modalOpen && (modalContent !== null)" @modalClose="modalOpen = false">
          <template v-slot:title>
            {{modalContent.info}}
          </template>

          <div v-html="modalContent.text"></div>

          <template v-if="type === 'photo' || type === 'docs'" v-slot:image>
            <img :src="baseUrl + modalContent.src" />
          </template>
          <template v-else-if="type === 'video'" v-slot:video>
            <YoutubeVue3 ref="yt-video" :videoid="modalContent.src" :autoplay="1" :controls="1" />
          </template>
        </ModalWindow>
      </Transition>
    </Teleport>
  </div>
</template>

<script>
import {Navigation} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css/bundle';
import { YoutubeVue3 } from 'youtube-vue3';
import ModalWindow from "@/components/ModalWindow";

export default {
  name: "MediaPopup",
  props: {
    type: String,
    content: Object
  },
  components: {
    ModalWindow,
    Swiper,
    SwiperSlide,
    YoutubeVue3
  },
  methods: {
    getButtonText(type){
      const buttonTexts = {
        'photo': 'Фотографии',
        'video': 'Видео',
        'docs': 'Документы'
      }
      return buttonTexts[type];
    },
    closeMedia(){
      this.$emit('closeMedia');
    }
  },
  data(){
    return{
      baseUrl: this.$axios.defaults.baseURL,
      modules: [Navigation],
      modalOpen: false,
      modalContent: null,
    }
  }
}
</script>

<style scoped>
  .swiper-fade .swiper-slide-active .swiper-slide{
    pointer-events: auto;
  }

  .media-popup{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  .media-popup__close{
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    width: 28px;
    height: 28px;
    background: url('img/icon-close.svg') center center / contain no-repeat;
    transition: all 0.4s ease;
  }
  .media-popup__close:hover{
    transform: rotate(180deg);
  }
  .media-popup__title{
    color: #D9D9D9;
    font-size: 1.2rem;
    line-height: 1.375;
    letter-spacing: 0.05em;
  }

  .media-popup.chapter_1 .media-popup__title{
    font-family: 'Playfair Display', serif;
  }
  .media-popup.chapter_2 .media-popup__title{
    font-family: 'Mulish', sans-serif;
  }
  .media-popup.chapter_3 .media-popup__title{
    font-family: 'Inter', sans-serif;
  }

  .media-slider{
    margin: 0 2.1rem;
    position: relative;
  }
  .media-slider.type-docs{
    margin: 0 1.5rem;
  }
  .media-slider .swiper{
    padding: 2.3rem 0 0.8rem;
  }
  .media-slider .item{
    margin: 0 0.85rem;
    cursor: pointer;
    display: block;
    transition: transform 0.4s ease;
  }
  .media-slider.type-docs .item{
    margin: 0 0.6rem;
  }
  @media(min-width: 576px){
    .media-slider .item:hover{
      transform: scale(1.1);
    }
    .media-slider.type-video .item:hover{
      transform: scale(1.06);
    }
  }

  .media-slider .arrow-next,
  .media-slider .arrow-prev{
    width: 1rem;
    height: 2rem;
    background: transparent center center / contain no-repeat;
    transform: translateY(-50%);
    position: absolute;
    top: 48%;
    cursor: pointer;
    transition: opacity 0.4s ease;
  }
  .media-slider .swiper-button-disabled{
    opacity: 0.25;
    cursor: default;
    pointer-events: none;
  }
  .media-slider .arrow-next{
    right: -2.1rem;
    background-image: url('img/arrow-right-big.svg');
  }
  .media-slider .arrow-prev{
    left: -2.1rem;
    background-image: url('img/arrow-left-big.svg');
  }
  .media-slider.type-docs .arrow-next{
    right: -1.5rem;
  }
  .media-slider.type-docs .arrow-prev{
    left: -1.5rem;
  }
  .media-slider__photo{
    padding: 9px;
    margin: 0 auto 0.8rem;
    background: transparent center center / 100% 100% no-repeat;
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.25);
  }
  .media-slider.type-photo .media-slider__photo{
    padding: 14px;
  }
  .media-popup.chapter_1 .media-slider__photo{
    background-color: #29292a;
  }
  .media-popup.chapter_1 .media-slider.type-photo .media-slider__photo{
    background-color: transparent;
    background-image: url('img/frame-ch1.png');
    box-shadow: none;
    filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.25));
  }
  .media-popup.chapter_2 .media-slider__photo{
    background-color: #543F2C;
  }
  .media-popup.chapter_2 .media-slider.type-photo .media-slider__photo{
    background-color: transparent;
    background-image: url('img/frame-ch2.jpg');
  }
  .media-popup.chapter_3 .media-slider__photo{
    background-color: #5E5E60;
  }

  .media-slider__photo-wrapper{
    padding-top: 100%;
    overflow: hidden;
    position: relative;
  }
  .media-slider.type-docs .media-slider__photo-wrapper{
    padding-top: 145%;
  }
  .media-slider.type-video .media-slider__photo-wrapper{
    padding-top: 54.2%;
  }
  .media-slider__photo-wrapper picture,
  .media-slider__photo-wrapper img{
    position: absolute;
    top: 50%;
    left: 50%;
    object-fit: cover;
    transform: translate(-50%, -50%);
    width: 115%;
    height: 115%;
  }
  .media-slider.type-docs .media-slider__photo-wrapper picture,
  .media-slider.type-docs .media-slider__photo-wrapper img{
    transform: translate(-50%, -5px);
    top: 0;
  }

  .media-slider__info{
    margin: 0 auto;
    font-size: 0.9rem;
    line-height: 1.333;
    letter-spacing: 0.05em;
    height: 2.666em;
    color: #B4A990;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .media-popup.chapter_1 .media-slider__info{
    font-family: 'Playfair Display', serif;
  }
  .media-popup.chapter_2 .media-slider__info{
    font-family: 'Mulish', sans-serif;
  }
  .media-popup.chapter_3 .media-slider__info{
    font-family: 'Inter', sans-serif;
  }

  @media (min-width: 1280px) and (max-height: 759px) {
    .media-slider.type-photo .media-slider__photo,
    .media-slider.type-photo .media-slider__info{
      width: 90%;
    }
    .media-slider.type-docs .media-slider__photo-wrapper{
      padding-top: 130%;
    }
  }
  @media(max-width: 1439px){
    .media-slider{
      margin: 0 1.5rem;
    }
    .media-slider .arrow-next{
      right: -1.5rem;
    }
    .media-slider .arrow-prev{
      left: -1.5rem;
    }
  }
  @media(max-width: 1279px){
    .media-popup{
      position: relative;
      margin-bottom: 28px;
    }
  }
  @media(max-width: 575px){
    .media-slider .swiper{
      padding-bottom: 0;
    }
    .media-popup__title{
      font-size: 18px;
    }
    .media-slider__info{
      font-size: 14px;
    }
    .media-slider{
      margin: 0 36px;
    }
    .media-slider .arrow-next{
      right: -36px;
    }
    .media-slider .arrow-prev{
      left: -36px;
    }
    .media-slider.type-docs{
      margin: 0 60px;
    }
    .media-slider.type-docs .arrow-next{
      right: -60px;
    }
    .media-slider.type-docs .arrow-prev{
      left: -60px;
    }
    .media-slider.type-video{
      margin: 0 30px;
    }
    .media-slider.type-video .arrow-next{
      right: -30px;
    }
    .media-slider.type-video .arrow-prev{
      left: -30px;
    }
    .media-slider .item{
      margin: 0 !important;
    }
    .media-slider .arrow-next,
    .media-slider .arrow-prev{
      width: 16px;
      height: 28px;
    }
    .media-popup__close{
      width: 18px;
      height: 18px;
    }
    .media-popup{
      margin-bottom: 24px;
    }
    .media-slider.type-docs .media-slider__photo-wrapper{
      padding-top: 140%;
    }
  }
</style>
