<template>
  <div class="stage" :class="chapter" v-if="titleDecor !== null">
    <template v-if="!checkMobile">
      <div class="stage-container" v-if="chapter === 'chapter_1'">
        <div class="sky"></div>
        <div class="sun"></div>
        <img :src="titleDecor.ch1.winterPalace" class="winter-palace" />
        <img :src="titleDecor.ch1.paint" class="paint" />
        <img :src="titleDecor.ch1.senatePalace" class="senate-palace" />
        <img :src="titleDecor.ch1.gravure" class="gravure" />
        <img :src="titleDecor.ch1.ship" class="ship" />
        <img :src="titleDecor.ch1.wharf" class="wharf" />
        <img :src="titleDecor.ch1.churchTrees" class="church-trees" />
        <img :src="titleDecor.ch1.lantern" class="lantern" />

        <img :src="titleDecor.ch1.peter" class="peter" />
        <img :src="titleDecor.ch1.carriage" class="carriage" />
        <img :src="titleDecor.ch1.paperBack" class="paper-back" />
        <img :src="titleDecor.ch1.fenceBack" class="fence-back" />
        <img :src="titleDecor.ch1.alexander" class="alexander" />
        <img :src="titleDecor.ch1.fenceFront" class="fence-front" />
        <img :src="titleDecor.ch1.crown" class="crown" />
        <img :src="titleDecor.ch1.deacons" class="deacons" />
        <img :src="titleDecor.ch1.birds" class="birds" />

        <img :src="titleDecor.ch1.paperTop" class="paper-top" />
      </div>
      <div class="stage-container" v-else-if="chapter === 'chapter_2'">
        <div class="sky"></div>
        <div class="sun"></div>
        <img :src="titleDecor.ch2.pavilion" class="pavilion" />
        <img :src="titleDecor.ch2.clouds" class="clouds" />
        <img :src="titleDecor.ch2.gosplan" class="gosplan" />
        <img :src="titleDecor.ch2.mid" class="mid" />
        <img :src="titleDecor.ch2.revolution" class="revolution" />
        <img :src="titleDecor.ch2.paperBottom" class="paper-bottom" />
        <img :src="titleDecor.ch2.wires" class="wires" />
        <img :src="titleDecor.ch2.tram" class="tram" />
        <img :src="titleDecor.ch2.trafficLights" class="traffic-lights" />
        <img :src="titleDecor.ch2.lenin" class="lenin" />
        <img :src="titleDecor.ch2.flag" class="flag" />
        <img :src="titleDecor.ch2.rocket" class="rocket" />
        <img :src="titleDecor.ch2.children" class="children" />
        <img :src="titleDecor.ch2.sputnik" class="sputnik" />

        <img :src="titleDecor.ch1.paperTop" class="paper-top" />
      </div>
      <div class="stage-container" v-else-if="chapter === 'chapter_3'">
        <img :src="titleDecor.ch3.bgSky" class="bg-sky" />
        <div class="sky"></div>
        <div class="sun"></div>
        <img :src="titleDecor.ch3.plane" class="plane" />
        <img :src="titleDecor.ch3.builtinsBridge" class="buildings-bridge" />
        <img :src="titleDecor.ch3.builtinFlag" class="building-flag" />
        <img :src="titleDecor.ch3.archTrees" class="arch-trees" />
        <img :src="titleDecor.ch3.bridge" class="bridge" />
        <img :src="titleDecor.ch3.monp" class="monp" />
        <img :src="titleDecor.ch3.monastery" class="monastery" />
        <img :src="titleDecor.ch3.treesBig" class="trees-big" />
        <img :src="titleDecor.ch3.roadsRiver" class="roads-river" />
        <img :src="titleDecor.ch3.boat" class="boat" />
        <img :src="titleDecor.ch3.robot" class="robot" />
        <img :src="titleDecor.ch3.treesSmall" class="trees-small" />
        <img :src="titleDecor.ch3.tram" class="tram-3" />
        <img :src="titleDecor.ch3.roads" class="roads" />
        <img :src="titleDecor.ch3.paperBottom" class="paper-decor" />
        <img :src="titleDecor.ch3.people" class="people" />

        <img :src="titleDecor.ch1.paperTop" class="paper-top" />
      </div>
    </template>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "TitlePageDecor",
  props: ['chapter'],
  computed: {
    ...mapGetters(['checkMobile']),
  },
  data(){
    return{
      titleDecor: null
    }
  },
  mounted(){
    if (localStorage.getItem('titleDecor')){
      try {
        this.titleDecor = JSON.parse(localStorage.getItem('titleDecor'));
      } catch(error) {
        console.log(error);
      }
    }
  }
}
</script>

<style scoped>
  .stage{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    --duration-short: 0.5s;
    --duration-long: 1s;
  }
  .stage-container{
    min-width: 1920px;
    min-height: 840px;
    position: relative;
    width: 100%;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
  .stage-container > *{
    position: absolute;
    pointer-events: none;
  }
  .sky{
    width: 1311px;
    height: 808px;
    border-radius: 50%;
    background: #C9DCE0;
    filter: blur(54px);
    left: 7px;
    top: -30px;
  }
  .sun{
    width: 530px;
    height: 530px;
    border-radius: 50%;
    background: #F2F2D9;
    filter: blur(155px);
    top: -88px;
    left: 45px;
  }
  .winter-palace{
    width: 853px;
    right: 120px;
    bottom: 50px;
  }
  .paint{
    width: 1322px;
    left: 2px;
    top: 5px;
  }
  .senate-palace{
    width: 1335px;
    bottom: -5px;
    left: -10px;
  }
  .gravure{
    width: 280px;
    left: 0;
    top: 40px;
  }
  .church-trees{
    width: 773px;
    bottom: -110px;
    left: 788px;
  }
  .lantern{
    width: 153px;
    left: 25px;
    bottom: 10px;
  }
  .carriage{
    width: 1065px;
    left: -81px;
    bottom: -98px;
  }
  .ship{
    width: 1094px;
    bottom: -111px;
    right: -707px;
  }
  .wharf{
    width: 1168px;
    right: -505px;
    bottom: -100px;
  }
  .peter{
    right: 370px;
    width: 1246px;
    bottom: -208px;
  }
  .fence-back{
    width: 466px;
    left: 404px;
    bottom: -114px;
  }
  .fence-front{
    width: 937px;
    left: 20px;
    bottom: -208px;
  }
  .crown{
    width: 150px;
    left: 806px;
    bottom: 156px;
  }
  .alexander{
    width: 600px;
    left: 120px;
    bottom: -205px;
  }
  .paper-back{
    width: 220px;
    bottom: 0;
    left: 680px;
  }
  .deacons{
    width: 349px;
    right: 125px;
    bottom: -220px;
  }
  .birds{
    width: 103px;
    top: 93px;
    right: 672px;
  }

  .paper-top{
    top: 0;
    left: 0;
    width: 1004px;
  }
  .paper-top--mobile{
    top: 0;
    left: 0;
    width: 100%;
    display: none;
    height: 23%;
  }

  @media(min-width: 1280px){
    /* animation сh.1 */
    .winter-palace{
      transform: translate(15%, 0);
      opacity: 0;
      transition: opacity var(--duration-short) ease 0s, transform var(--duration-long) ease 0s;
    }
    .paint{
      opacity: 0;
      transition: opacity var(--duration-long) ease 0.2s
    }
    .senate-palace{
      transform: translate(-15%, 0);
      opacity: 0;
      transition: opacity var(--duration-short) ease 0.4s, transform var(--duration-long) ease 0.4s;
    }
    .church-trees{
      transform: translate(-10%, 5%);
      opacity: 0;
      transition: opacity var(--duration-short) ease 0.4s, transform var(--duration-long) ease 0.4s;
    }
    .peter{
      transform: translate(0, 8%);
      opacity: 0;
      transition: opacity var(--duration-short) ease 0.6s, transform var(--duration-long) ease 0.6s;
    }
    .wharf{
      transform: translate(12%, 15%);
      opacity: 0;
      transition: opacity var(--duration-short) ease 0.7s, transform var(--duration-long) ease 0.7s;
    }
    .ship{
      transform: translate(25%, 0);
      opacity: 0;
      transition: opacity var(--duration-short) ease 0.8s, transform var(--duration-long) ease 0.8s;
    }
    .deacons{
      opacity: 0;
      transition: opacity var(--duration-short) ease 0.65s, transform var(--duration-long) ease 0.65s;
    }
    .lantern{
      transform: translate(-33%, 0);
      opacity: 0;
      transition: opacity var(--duration-short) ease 0.75s, transform var(--duration-long) ease 0.75s;
    }
    .carriage{
      transform: translate(-40%, 0);
      opacity: 0;
      transition: opacity var(--duration-short) ease 1s, transform var(--duration-long) ease 1s;
    }
    .paper-back,
    .fence-back,
    .fence-front{
      opacity: 0;
      transition: opacity var(--duration-short) ease 1.2s;
    }
    .alexander{
      transform: translate(-18%, 0);
      opacity: 0;
      transition: opacity var(--duration-short) ease 1.5s, transform var(--duration-long) ease 1.5s;
    }
    .crown{
      opacity: 0;
      transform: translate(0, -10%);
      transition: opacity var(--duration-short) ease 2s, transform var(--duration-short) ease 2s;
    }
    .birds{
      transform: scale(0.8) translate(-80%, 0);
      opacity: 0;
      transition: opacity var(--duration-short) ease 1.5s, transform var(--duration-long) ease 1.5s;
    }

    .passed .winter-palace,
    .passed .senate-palace,
    .passed .church-trees,
    .passed .peter,
    .passed .wharf,
    .passed .ship,
    .passed .lantern,
    .passed .alexander,
    .passed .crown,
    .passed .carriage{
      transform: translate(0%, 0%);
      opacity: 1;
    }
    .passed .birds{
      transform: scale(1) translate(0%, 0%);
      opacity: 1;
    }
    .passed .paint,
    .passed .paper-back,
    .passed .fence-back,
    .passed .fence-front,
    .passed .deacons{
      opacity: 1;
    }
  }

  /* chapter 2 */
  .pavilion{
    width: 1920px;
    bottom: -57px;
    left: 0;
  }
  .clouds{
    width: 531px;
    bottom: 96px;
    right: -113px;
  }
  .gosplan{
    width: 1270px;
    left: -140px;
    bottom: -215px;
  }
  .mid{
    width: 676px;
    bottom: 70px;
    right: 358px;
  }
  .revolution{
    width: 1561px;
    bottom: -180px;
    left: 150px;
  }
  .paper-bottom{
    width: 769px;
    bottom: 0;
    left: 498px;
  }
  .wires{
    width: 561px;
    left: -254px;
    bottom: 324px;
  }
  .tram{
    width: 632px;
    left: -458px;
    bottom: -147px;
  }
  .traffic-lights{
    width: 127px;
    left: 9px;
    top: 116px;
  }
  .lenin{
    width: 826px;
    bottom: -180px;
    left: 75px;
  }
  .flag{
    width: 1184px;
    left: -335px;
    bottom: -638px;
  }
  .rocket{
    width: 332px;
    top: 48px;
    right: -40px;
  }
  .children{
    width: 691px;
    bottom: -63px;
    right: -162px;
  }
  .sputnik{
    width: 123px;
    right: 635px;
    top: 64px;
  }

  @media(min-width: 1280px){
    /* animation сh.2 */
    .pavilion{
      transform: translate(0%, 20%);
      opacity: 0;
      transition: opacity var(--duration-short) ease 0s, transform var(--duration-long) ease 0s;
    }
    .clouds{
      transform: translate(20%, 0%);
      opacity: 0;
      transition: opacity var(--duration-short) ease 0.2s, transform var(--duration-long) ease 0.2s;
    }
    .gosplan{
      transform: translate(0%, 20%);
      opacity: 0;
      transition: opacity var(--duration-short) ease 0.3s, transform var(--duration-long) ease 0.3s;
    }
    .mid{
      transform: translate(0%, 20%);
      opacity: 0;
      transition: opacity var(--duration-short) ease 0.5s, transform var(--duration-long) ease 0.5s;
    }
    .revolution,
    .paper-bottom{
      transform: translate(0%, 20%);
      opacity: 0;
      transition: opacity var(--duration-short) ease 0.7s, transform var(--duration-long) ease 0.7s;
    }
    .wires{
      opacity: 0;
      transition: opacity var(--duration-short) ease 0.8s;
    }
    .tram{
      transform: translate(-20%, 0%);
      opacity: 0;
      transition: opacity var(--duration-short) ease 0.9s, transform var(--duration-long) ease 0.9s;
    }
    .traffic-lights{
      transform: translate(0%, -15%);
      opacity: 0;
      transition: opacity var(--duration-short) ease 1s, transform var(--duration-long) ease 1s;
    }
    .lenin{
      transform: translate(-10%, 0%);
      opacity: 0;
      transition: opacity var(--duration-short) ease 1.2s, transform var(--duration-long) ease 1.2s;
    }
    .flag{
      transform: translate(-10%, 0%);
      opacity: 0;
      transition: opacity var(--duration-short) ease 1.3s, transform var(--duration-long) ease 1.3s;
    }
    .rocket{
      transform: translate(0%, 40%);
      opacity: 0;
      transition: opacity var(--duration-short) ease 1.4s, transform var(--duration-long) ease 1.4s;
    }
    .children{
      transform: translate(10%, 0%);
      opacity: 0;
      transition: opacity var(--duration-short) ease 1.7s, transform var(--duration-long) ease 1.7s;
    }
    .sputnik{
      transform: translate(-15%, -15%);
      opacity: 0;
      transition: opacity var(--duration-short) ease 1.6s, transform var(--duration-long) ease 1.6s;
    }

    .passed .pavilion,
    .passed .clouds,
    .passed .gosplan,
    .passed .mid,
    .passed .revolution,
    .passed .paper-bottom,
    .passed .tram,
    .passed .traffic-lights,
    .passed .lenin,
    .passed .flag,
    .passed .rocket,
    .passed .children,
    .passed .sputnik{
      transform: translate(0%, 0%);
      opacity: 1;
    }
    .passed .wires{
      opacity: 1;
    }
  }

  /* chapter 3 */
  .bg-sky{
    width: 1920px;
    top: 0;
    right: 0;
  }
  .plane{
    width: 1089px;
    right: 106px;
    top: 70px;
  }
  .buildings-bridge{
    width: 1060px;
    left: 462px;
    bottom: 158px;
  }
  .building-flag{
    width: 498px;
    left: -68px;
    bottom: 280px;
  }
  .arch-trees{
    width: 931px;
    left: -127px;
    bottom: 10px;
  }
  .bridge{
    width: 880px;
    right: -369px;
    bottom: -210px;
  }
  .monp{
    width: 405px;
    bottom: 107px;
    right: 230px;
  }
  .monastery{
    width: 452px;
    right: -6px;
    bottom: 128px;
  }
  .trees-big{
    width: 604px;
    right: -134px;
    bottom: -37px;
  }
  .roads-river{
    width: 999px;
    right: -30px;
    bottom: -286px;
  }
  .boat{
    width: 206px;
    bottom: -56px;
    right: -163px;
  }
  .robot{
    width: 130px;
    bottom: -3px;
    right: 95px;
  }
  .trees-small{
    width: 655px;
    bottom: -102px;
    right: 515px;
  }
  .tram-3{
    width: 556px;
    left: 479px;
    bottom: 80px;
  }
  .roads{
    width: 1390px;
    bottom: -254px;
    left: 278px;
  }
  .paper-decor{
    width: 807px;
    bottom: 0;
    right: 710px;
  }
  .people{
    width: 1100px;
    left: -175px;
    bottom: -280px;
  }

  @media(min-width: 1280px){
    /* animation сh.3 */
    .plane{
      transform: translate(-20%, 5%);
      opacity: 0;
      transition: opacity var(--duration-short) ease 1.25s, transform var(--duration-long) ease 1.25s;
    }
    .buildings-bridge{
      opacity: 0;
      transition: opacity var(--duration-long) ease 0.7s;
    }
    .building-flag{
      opacity: 0;
      transition: opacity var(--duration-long) ease 0.8s;
    }
    .arch-trees{
      opacity: 0;
      transition: opacity var(--duration-long) ease 1s;
    }
    .bridge{
      opacity: 0;
      transition: opacity var(--duration-long) ease 0.9s;
    }
    .monp{
      opacity: 0;
      transition: opacity var(--duration-long) ease 0.6s;
    }
    .monastery{
      opacity: 0;
      transition: opacity var(--duration-long) ease 0.5s;
    }
    .trees-big{
      opacity: 0;
      transition: opacity var(--duration-long) ease 0.3s;
    }
    .roads-river{
      opacity: 0;
      transition: opacity var(--duration-long) ease 0.25s;
    }
    .boat{
      transform: translate(15%, 15%);
      opacity: 0;
      transition: opacity var(--duration-short) ease 1.4s, transform var(--duration-long) ease 1.4s;
    }
    .robot{
      transform: translate(15%, -15%);
      opacity: 0;
      transition: opacity var(--duration-short) ease 1.5s, transform var(--duration-long) ease 1.5s;
    }
    .trees-small{
      opacity: 0;
      transition: opacity var(--duration-long) ease 0.4s;
    }
    .tram-3{
      opacity: 0;
      transition: opacity var(--duration-long) ease 1.2s;
    }
    .roads{
      transform: translate(0%, 10%);
      opacity: 0;
      transition: opacity var(--duration-short) ease 0s, transform var(--duration-long) ease 0s;
    }
    .paper-decor{
      opacity: 0;
      transition: opacity var(--duration-long) ease 0.1s;
    }
    .people{
      transform: translate(-10%, 0%);
      opacity: 0;
      transition: opacity var(--duration-short) ease 0.2s, transform var(--duration-long) ease 0.2s;
    }

    .passed .plane,
    .passed .boat,
    .passed .robot,
    .passed .roads,
    .passed .people{
      transform: translate(0%, 0%);
      opacity: 1;
    }
    .passed .buildings-bridge,
    .passed .building-flag,
    .passed .arch-trees,
    .passed .bridge,
    .passed .monp,
    .passed .monastery,
    .passed .trees-big,
    .passed .trees-small,
    .passed .tram-3,
    .passed .roads-river,
    .passed .paper-decor{
      opacity: 1;
    }
  }

  @media(min-width: 1280px) and (max-width: 1439px){
    .stage-container{
      min-width: 1800px;
    }
  }
  @media(max-width: 1279px){
    .stage-container{
      min-width: 0;
      min-height: 0;
      display: none;
    }
  }
  @media(max-width: 1279px) and (orientation: landscape){
    .stage.chapter_1{
      background: url('../components/img/chapter-1/bg-landscape.jpg') center bottom / cover no-repeat;
    }
    .stage.chapter_2{
      background: url('../components/img/chapter-2/bg-landscape.jpg') center bottom / cover no-repeat;
    }
    .stage.chapter_3{
      background: url('../components/img/chapter-3/bg-landscape.jpg') center bottom / cover no-repeat;
    }
  }
  @media(max-width: 1279px) and (orientation: portrait){
    .stage:before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 23%;
      background: url('../components/img/chapter-1/paper-top--mobile.png') center top / 100% 100% no-repeat;
    }
    .stage.chapter_1{
      background: url('../components/img/chapter-1/bg-portrait.jpg') center 66% / cover no-repeat;
    }
    .stage.chapter_2{
      background: url('../components/img/chapter-2/bg-portrait.jpg') center 66% / cover no-repeat;
    }
    .stage.chapter_3{
      background: url('../components/img/chapter-3/bg-portrait.jpg') center 66% / cover no-repeat;
    }
  }
</style>
